import React from 'react';


const CourseSecondarySection = ({ title, text, features }) => {
    return (
        <>
            <div className="col-10 col-lg-12 max-auto mb-5 mr-0">
                <div className="card" >
                    <div className="row card-body">
                        <div className="col-sm-12">
                            <h3 className="card-title mt-2 mb-5 text-center">
                                {title}
                            </h3>
                            <p className="card-text">
                                {text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {
                features.map(l =>
                    <Feature title={l.title} text={l.text} />
                )

            }
        </>
    );
}

export default CourseSecondarySection;

const Feature = ({ title, text }) => {
    return (
        <div className="col-10 col-lg-4 max-auto mb-5">
            <div className="card h-100">
                <div className="card-body">
                    <h3 className="card-title text-center">
                        {title}
                    </h3>
                    <p className="card-text">
                        {text}
                    </p>
                </div>
            </div>
        </div>
    );
}

