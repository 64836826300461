import React from 'react';
import { useState, useEffect } from 'react';

const InfoSection = ({ id, title, text, img, imgPosition, size = 12}) => {
    const [resize, setResize] = useState(false);

    const handleResize = () => {
        if (window.innerWidth > 760)
            setResize(false);
        else
            setResize(true);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);

    return (
            <div className={`col-10 col-lg-${size} max-auto mb-5 mr-0`}>
                <div className="card" style={ {"white-space": "pre-wrap"}} >
                    <div className="row card-body">
                        {
                            (imgPosition === "left") ?
                                (<img src={img} alt={title} className={`col-sm-6 ${resize?"mt-3 pt-3":"mt-5 pt-5"} h-100`} />) :
                                (<></>)
                        }
                        {
                            (imgPosition === "before") ?
                                (<img src={img} alt={title} className={`col-sm-12 ${resize?"mt-3 pt-3":"mt-5 pt-5"}`} />) :
                                (<></>)
                        }
                        <div className={imgPosition==="left" || imgPosition==="right"?"col-sm-6": "col-sm-12"}>
                            <h3 className={`card-title mt-2 ${resize?"mb-3":"mb-5"}`}>
                                {title}
                            </h3>
                            <p className="card-text">
                                {text}
                            </p>
                        </div>
                        {
                            (imgPosition === "right") ?
                                (<img src={img} alt={title} className={`col-sm-6 ${resize?"mt-3 pt-3":"mt-5 pt-5"} h-100`} />) :
                                (<></>)
                        }
                        {
                            (imgPosition === "after") ?
                                (<img src={img} alt={title} className={`col-sm-12 ${resize?"mt-3 pt-3":"mt-5 pt-5"}`} />) :
                                (<></>)
                        }
                    </div>
                </div>
            </div>
    );
}

export default InfoSection;