
const header_content = [
    {
        version: "en",
        content: {
            home: "Home",
            about: "About Us",
            lectures: "Lectures",
            news: "News",
            courses: {
                text: "courses",
                math: "Mathmatics",
                en: "EAL&English",
                ch: "VCE Chinese",
                ib: "IB Courses",
                el: "IELTS&PTE",
                uni: "University Tutorials",
                other: "Other Courses"
            },
            join: "Join Us",
            contact: "Contact",
            lan: "中文",
            calc: "ATAR Calculator"
        }
    },
    {
        version: "ch",
        content: {
            home: "首页",
            about: "关于我们",
            lectures: "讲座",
            news: "最新资讯",
            courses: {
                text: "课程",
                math: "数学",
                en: "EAL和English",
                ch: "VCE中文",
                ib: "IB 课程",
                el: "雅思和PTE",
                uni: "大学课程辅导",
                other: "其他课程"
            },
            join: "加入我们",
            contact: "取得联系",
            lan: "English",
            calc: "ATAR 计算器"
        }
    }
];

const footer_content = [
    {
        version: "en",
        content: {
            campuses: [
                {
                    name: "GLEN Campus",
                    address: "Unit 5, 251 Blackburn Road, Mt Waverley VIC 3149",
                    phone: "+61 424970311",
                    QR: "/logo512.png"
                },
                {
                    name: "CITY Campus",
                    address: "level 10 Suite 5, 343 Little Collins Street, Melbourne, VIC 3000",
                    phone: "+61 450912588",
                    QR: "/logo512.png"
                }
            ],
            social: {
                follow: "Follow Us",
                connect: "Connect with Us",
                facebook: "Facebook",
                twitter: "Twitter",
                googleplus: "Google Plus",
                linkedin: "Linked In",
                whatsapp: "WhatsApp",
                wechat: "WeChat"
            }
        }
    },
    {
        version: "ch",
        content: {
            campuses: [
                {
                    name: "GLEN 校区",
                    address: "Unit 5， 251 Blackburn Road, Mt Waverley VIC 3149",
                    phone: "+61 424970311",
                    QR: "/logo512.png"
                },
                {
                    name: "CITY 校区",
                    address: "level 10 Suite 5, 343 Little Collins Street, Melbourne, VIC 3000",
                    phone: "+61 450912588",
                    QR: "/logo512.png"
                }
            ],
            social: {
                follow: "请关注我们",
                connect: "并保持联系",
                facebook: "脸书",
                twitter: "推特",
                googleplus: "Google+",
                linkedin: "领英",
                whatsapp: "WhatsApp",
                wechat: "微信"
            }
        }
    }
];

const home_content = [
    {
        version: "en",
        content: {
            title: "Hello World",
            background: "Photo4.png",
            ach_section: {
                title: "Our Achievement",
                achievements: [
                    {
                        id: 1,
                        logo: "fas fa-medal",
                        title: "80%",
                        text: "In 2020,  Tsingteng students who have the total score over ATAR 95 in VCE is up to 80%"
                    },
                    {
                        id: 2,
                        logo: "fas fa-thumbs-up",
                        title: "88%",
                        text: "In 2020, 88% of Tsingteng students got the ATAR over 90"
                    },
                    {
                        id: 3,
                        logo: "fas fa-trophy",
                        title: "93%",
                        text: "In 2021, Tsingteng students who have the total score over ATAR 95 in VCE is up to 93%"
                    },
                    {
                        id: 4,
                        logo: "fas fa-certificate",
                        title: "90%",
                        text: "In 2021, 90% of singteng students got the ATAR over 90"
                    }
                ]
            },
            video_section: {
                title: "ABC Tutoring Introduction",
                embedId: "AdeC_Z_67fo"
            },
            info_section: {
                infos: [
                    {
                        id: 1,
                        title: "Reliable Teaching Quality",
                        text: "Tsingteng Education has always been in awe of education, adhering to the idea of \"student-centred, to know is easy, but to do is difficult\", and steadily continues to develop and progress. In order to help students grow efficiently and steadily, Tsingteng insists on small class teaching and then teaches according to aptitude. In the classroom with small class teaching, teachers can better consider the individual differences of students, and adjust the learning level of students at any time, so that students can learn more comprehensive.",
                        img: "/teaching_quality.png",
                        img_position: "right"
                    },
                    {
                        id: 2,
                        title: "Professional faculty team",
                        text: "The experienced team of teachers will create teaching materials suitable for students' understanding according to the course structure, ensuring that the content is clearly focused. While instructing students professionally and rigorously, teachers will ensure that the interaction with students is interesting, using vivid and interesting examples to combine theory and practice to enhance their memory. The unique and effective teaching methods and patient explanations can not only provide students with the construction of mathematical logic, but also expand more knowledge on the basis of taking the exam to pave the way for the next study life. After class, we are willing to provide students with information consultation, including course selection guidance, study plans, etc. Tsingteng's teachers are not simple knowledge transmitters, but through the interaction between teaching and learning, the optimal allocation and utilization of resources, so that each student's learning ability can be fully developed.",
                        img: "/course_choice.png",
                        img_position: "left"
                    },
                    {
                        id: 3,
                        title: "Thoughtful learning services",
                        text: "Tsingteng Education provides free targeted online Q&A to enhance students' knowledge mastery and deepen classroom understanding. Online Q&A has the characteristics of real-time interaction, students can ask questions during the answering process, and there is a lot of information feedback between teachers and students. The online teacher Q&A team will not only answer students' questions in a timely manner, but also accurately grasp the learning situation. At the same time, teaching strategies can be continuously adjusted in response to student feedback. While explaining the topic clearly, the online teacher Q&A team will also sort out the key knowledge points in the topic and explain the relevant knowledge points, so that students' understanding of this knowledge point can be enhanced.",
                        img: "/teaching_materials.png",
                        img_position: "right"
                    }
                ]
            },
            admission_section: {
                title: "Admission Process",
                img: "/admission_process.jpg"
            },
            news_section: {
                title: "Education News"
            }
        }
    },
    {
        version: "ch",
        content: {
            title: "你好",
            background: "Photo4.png",
            ach_section: {
                title: "我们的成就",
                achievements: [
                    {
                        id: 1,
                        logo: "fas fa-medal",
                        title: "60%",
                        text: "2020年青藤学子VCE总分超过95高达60%"
                    },
                    {
                        id: 2,
                        logo: "fas fa-thumbs-up",
                        title: "80%",
                        text: "2020年青藤学子VCE总分90+超过80%"
                    },
                    {
                        id: 3,
                        logo: "fas fa-trophy",
                        title: "70%",
                        text: "2021年青藤学子VCE总分超过95高达70%"
                    },
                    {
                        id: 4,
                        logo: "fas fa-certificate",
                        title: "85%",
                        text: "2021年青藤学子VCE总分90+超过85%"
                    }
                ]
            },
            video_section: {
                title: "ABC Tutoring 介绍",
                embedId: "AdeC_Z_67fo"
            },
            info_section: {
                infos: [
                    {
                        id: 1,
                        title: "可信的教学质量",
                        text: "青藤教育始终怀着对教育的敬畏之心，秉承着“学生为中心，知易行难”的思想，踏踏实实的不断发展和进步。为了帮助学生高效的稳步成长，青藤坚持小班化教学进而因材施教。旨在为每位学生提供充足的学习空间、活动空间及教育资源。入学时，老师会根据学生们的学习情况进行分组，进而为每个班级设计出有针对性地教研材料。在小班化教学的课堂上，老师们能更好考虑到学生的个体差异，综合学生的学习状况随时进行调节，使学生学习知识更扎实。",
                        img: "/teaching_quality.png",
                        img_position: "right"
                    },
                    {
                        id: 2,
                        title: "专业的教师团队",
                        text: "经验丰富的教师青藤团队会根据课程结构打造适合学⽣理解的教学资料，保证内容层次分明，重点突出。专业严谨指导学生的同时，老师们也会确保与学生互动的趣味性，运⽤⽣动有趣的事例将理论与实践相结合，加深记忆。辅以及独特有效的教学方法及耐⼼讲解不仅能为学⽣提供数理逻辑的构建，同时在应试的基础上拓展了更多知识以为接下来的学习生活进⾏铺垫。课后更是乐于给学生们提供信息咨询，包括选课指导，学习计划等。青藤的老师们不是简单的知识传授者，而是用心通过教与学的互动交往、资源的优化配置和利用，强调学生在班级活动中的主体地位，让每一个学生的学习能力得到充分发展。",
                        img: "/course_choice.png",
                        img_position: "left"
                    },
                    {
                        id: 3,
                        title: "周到的学习服务",
                        text: "青藤教育免费提供针对性的线上答疑，以提升学⽣的知识掌握度并加深课堂理解。线上答疑有实时交互的特性，学生可在答题过程中提问，师生间的信息反馈很多。线上教师答疑组不仅会及时解答学生疑问，精准把握学情，实施质量过硬的辅导答疑。同时也可针对学生的反馈来不断进行教学策略的调整。线上教师答疑组在把题目解释得清楚的同时，还会把题目中的关键知识点理出来，给提问者讲解得头头是道。通过讲解题目，把相关的关键知识点做了讲解，使学生在此知识点上的理解更深入一步，即把相当的知识点做了复习。而且通过向提问者不断发问，从而了解学习者的水平及思维特点，并能够针对学习者的特点，启发他的思维，从而通过一道题目，使提问者能够透彻理解知识点应用，做到“举一反三”。",
                        img: "/learning-service.png",
                        img_position: "right"
                    }
                ]
            },
            admission_section: {
                title: "入学流程",
                img: "/admission_process.jpg"
            },
            news_section: {
                title: "最新教育资讯"
            }
        }
    }
];

const about_content = [
    {
        version: "en",
        content: {
            title: "About Us",
            intr_section: {
                title: "About ABC",
                text: "Tsingteng Education was established in 2020 by Victorian registered teachers, VCE examiners, doctors of mathematics and science, and VCE high scorers. At present, it is one of the most professional training institutions engaged in VCE teaching in Melbourne. Excellent teachers are the core value of Tsingteng education. It is the mission of Tsingteng Education to help students get ready for their exams. Since its successful establishment, after a few years of continuous development, it has two directly operated campuses located in Melbourne's CBD and Glen. While providing high-quality examination ability education (private school scholarship examination, elite public school examination, VCE college entrance examination), Tsingteng Education has been committed to the exploration of various training courses and the continuous improvement of teaching methods. It aims to overcome learning difficulties for students, provide excellent educational resources, create a good learning environment, and help every future star realize their dreams."
            },
            score_section: {
                title: "Our Scores",
                headers: [
                    "Name",
                    "ATAR",
                    "School"
                ],
                annual_performs: [
                    {
                        year: "2018",
                        descripton: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    },
                    {
                        year: "2019",
                        descripton: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    },
                    {
                        year: "2020",
                        descripton: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    },
                    {
                        year: "2021",
                        descripton: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    }
                ],
                teams: [
                    {

                    }
                ]
            },
            team_section: {
                title: "Our Team",
                teams: [
                    {
                        id: 1,
                        title: "VCE liberal arts team",
                        text: "The liberal arts team is an excellent team of teachers with strong moral beliefs, solid and rigorous work, unity and cooperation, innovation, and love by students. The tutors have  excellent experience in tutoring, are good at summarizing knowledge points, and can pass on the key knowledge to students well, and will also share their learning experience with classmates in the process of tutoring, and help students answer questions from the perspective of students. In addition to rich teaching experience, all are familiar with the teaching mode of Victorian secondary and high schools. Advocate and guide students to understand knowledge points, master knowledge points, and apply knowledge points.",
                        img: "/art_team.png",
                        img_position: "right"
                    },
                    {
                        id: 2,
                        title: "VCE science team",
                        text: "We have great teachers who have experience in VCE for many years and, as well as young teachers who have undergone the training and have grown rapidly and stood firmly in the teaching of VCE. They have a sense of education, responsibility, love, teaching style, and great teaching achievements. Science teachers have been teaching for many years and have excellent mathematics results in the classes they teach. According to the course structure, create teaching content suitable for students' understanding, with clear levels and key points, and use vivid and interesting examples to combine theory and practice to deepen memory. The step-by-step course content and patient explanation can not only provide students with the construction of mathematical logic, but also expand more knowledge on the basis of the test to prepare for the next university study.",
                        img: "/sci_team.png",
                        img_position: "left"
                    },
                    {
                        id: 2,
                        title: "Administrative team",
                        text: "Tsingteng's administrative team has a high sense of responsibility and good communication skills. The team led by the head of the administration department has a clear division of labor, and at the same time cooperates with each other to comprehensively promote various tasks. The Tsingteng team is very familiar with the curriculum system of Australian high schools and preparatory undergraduate pathways. In turn, it can provide students with more scientific curriculum remedial planning and more targeted ability improvement programs, and help students adapt to the school's teaching process in a more targeted manner.",
                        img: "/admin_team.png",
                        img_position: "right"
                    }
                ]
            },
            gallary_section: [
                {
                    id: 1,
                    title: "GLEN Campus"
                },
                {
                    id: 2,
                    title: "CITY Campus"
                }
            ]
        }
    },
    {
        version: "ch",
        content: {
            title: "关于我们",
            intr_section: {
                title: "关于我们",
                text: "青藤教育（Tsingteng Education）成立于2020年，由维州注册教师、VCE考官、数理博士、VCE高分得主联合打造。目前是墨尔本最专业从事VCE教学的培训机构之一。 优秀的教师队伍是青藤教育的核心价值。为芊芊学子在考场之路保驾护航是青藤教育的使命。自成功创办以来，经过短短几年的不断发展，已拥有位于墨尔本市中心CBD区和geln的两所直营校区。青藤教育在提供优质的考试能力教育（私校奖学金考试，精英公校考试，VCE 高考）的同时，更是一直致力于各科培训课程的探索和教学方式的不断改进。 旨在为学⽣攻克学习难点，提供优渥教育资源，打造良好学习环境，培养⾼效学习能⼒，为今后的学习⽣活奠定坚实基础，助⼒每⼀位未来之星实现梦想。"
            },
            score_section: {
                title: "我们的成绩",
                headers: [
                    "姓名",
                    "ATAR",
                    "学校"
                ],
                annual_performs: [
                    {
                        year: "2018",
                        descripton: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张"
                    },
                    {
                        year: "2019",
                        descripton: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张"
                    },
                    {
                        year: "2020",
                        descripton: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张"
                    },
                    {
                        year: "2021",
                        descripton: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张"
                    }
                ]
            },
            team_section: {
                title: "我们的团队",
                teams: [
                    {
                        id: 1,
                        title: "VCE 文科团队",
                        text: "文科团队是一个师德信念作风过硬、工作踏实认真严谨、团结合作锐意创新、深受学生喜爱的优秀教师团队。 辅导老师均有丰富的补习经验，善于归纳总结知识点，并且可以很好的将知识点传授给学生，同时也会在补习的过程中将自己的学习经验分享给同学，从学生的角度帮助学生解答问题。除了具备丰富授课经验外，全部熟悉维州中学、高中的教学模式。提倡引导学生理解知识点，掌握知识点，运用知识点。",
                        img: "/art_team.png",
                        img_position: "right"
                    },
                    {
                        id: 2,
                        title: "VCE 理科团队",
                        text: "既有扎根讲台多年、经验丰富的名师,也有经过青年教师培训,迅速成长,站稳讲台的教坛新秀。他们有一个共同特点,就是有教育情怀,有责任担当,有包容爱心,有教学风格,有教学实绩。 理科教师从教多年,所任教班级数学成绩优异。根据课程结构打造适合学⽣理解的教学内容，层次分明，重点突出，运⽤⽣动有趣的事例将理论与实践相结合，加深记忆。循序渐进的课程内容以及耐⼼的讲解不仅能为学⽣提供数理逻辑的构建，同时在应试的基础上拓展了更多知识以为接下来的⼤学学习进⾏铺垫。",
                        img: "/sci_team.png",
                        img_position: "left"
                    },
                    {
                        id: 3,
                        title: "VCE 管理团队",
                        text: "青藤的行政团队具备高度的责任心及良好的沟通能力。由行政部负责人带领的团队,各自有着明确的分工，同时又相互配合全面地推进各项工作。青藤团队十分熟悉理解澳洲本地高中、预科本科衔接的课程体制。进而能够为学生提供更科学的课程补习规划以及更有针对性的能力提升方案，更有针对性帮助学生适应学校的教学进程",
                        img: "/admin_team.png",
                        img_position: "right"
                    }
                ]
            },
            gallary_section: [
                {
                    id: 1,
                    title: "GLEN 校区"
                },
                {
                    id: 2,
                    title: "CITY 校区"
                }
            ]
        }
    }
];

const lectures_content = [
    {
        version: "en",
        content: {
            title: "Lectures",
            on_on_one_title: "Book a one-on-one learning plan",
            genders: {
                none: "",
                male: "Male",
                female: "Female",
            },
            grades: {
                none: "",
                year1: "Year 1",
                year2: "Year 2",
                year3: "Year 3",
                year4: "Year 4",
                year5: "Year 5",
                year6: "Year 6",
                year7: "Year 7",
                year8: "Year 8",
                year9: "Year 9",
                year10: "Year 10",
                year11: "Year 11",
                year12: "Year 12",
                uni: "University First Year"
            },
            campuses: {
                none: "",
                campus1: "Campus A",
                campus2: "Campus B"
            },
            subjects: {
                none: "",
                en: "EAL&English",
                math: "Mathmatics",
                ch: "Chinese",
                other: "Others"
            },
            esp_lecture_time: {
                date_time1: "Date Time 1",
                date_time2: "Date Time 2"
            },
            ce_lecture_time: {
                date_time1: "Date Time 1",
                date_time2: "Date Time 2"
            },
            hear_from: {
                school: "Students or parents in school",
                friends: "Introduced by friends",
                wechat: "Wechat Moments",
                network: "Network",
                partner: "TsingTung's partners",
                other: "Other"
            },
            favorite_uni: {
                mu: "University of Melbourne",
                monash: "Monash University",
                su: "University of Sydney",
                unsw: "The University of New South Wales",
                nu: "National university",
                rmit: "RMIT",
                other: "Other"
            },
            favorite_major:{
                bs: "Business Studies",
                st: "Science Technology",
                bio: "Biomedicine",
                edu: "Education",
                art: "Art",
                other: "Other"
            },
            error_book: {
                first_name_missing: "First name is mandatory",
                last_name_missing: "Last name is mandatory",
                gender_missing: "Gender must be specified",
                phone_number_missing: "Phone number is mandatory",
                email_missing: "Email is mandatory",
                grade_missing: "Grade must be selected",
                campus_missing: "Campus must be selected",
                school_missing: "School is mandatory",
                phone_number_invalid: "Invald phone number",
                email_invalid: "Invalid email address",
                phone_number_duplicate: "Phone number <> has been used for multiple students",
                email_duplicate: "Email <> has been used for multiple students"
            },
            tab_title: {
                tab1: "Educational Science Popularization lectures",
                tab2: "College entrance examination-University lectures",
                tab3: "Scholarship/AEAS lecture",
                tab4: "CASPer Test lecture",
            },
            form_labels: {
                first_name: "First Name",
                last_name: "Last Name",
                ch_name: "Chinese Name",
                gender: "Gender",
                phone: "Phone Number",
                email: "Email",
                wechat: "Wechat",
                grade: "Grade",
                campus: "Campus",
                subject: "Interested subjust",
                school: "School",
                private_school: "Intended Private Shcool",
                add_student:"+ Add Student",
                remove: "- Remove",
                favorite_uni: "Favorite University",
                favorite_major: "Favorite Major",
                lecture_time: "Lecture Time",
                hear_from: "Where did you get to know TsingTung?",
                specify: "Please specify",
                submit: "Submit"
            }
        }
    },
    {
        version: "ch",
        content: {
            title: "讲座",
            on_on_one_title: "预约一对一学习规划",
            genders: {
                none: "",
                male: "男",
                female: "女",
            },
            grades: {
                none: "",
                year1: "1年级",
                year2: "2年级",
                year3: "3年级",
                year4: "4年级",
                year5: "5年级",
                year6: "6年级",
                year7: "7年级",
                year8: "8年级",
                year9: "9年级",
                year10: "10年级",
                year11: "11年级",
                year12: "12年级",
                uni: "大一"  
            },
            campuses: {
                none: "",
                campus1: "A校区",
                campus2: "B校区"
            },
            subjects: {
                none: "",
                en: "英文",
                math: "数学",
                ch: "中文",
                other: "其他"
            },
            esp_lecture_time: {
                date_time1: "教育科普讲座时间 1",
                date_time2: "教育科普讲座时间 2"
            },
            ce_lecture_time: {
                date_time1: "高考-大学讲座时间 1",
                date_time2: "高考-大学讲座时间 2"
            },
            hear_from: {
                school: "在读学生或家长",
                friends: "朋友介绍",
                wechat: "微信朋友圈",
                network: "网络",
                partner: "青藤合作伙伴",
                other: "其他"
            },
            favorite_uni: {
                mu: "墨尔本大学",
                monash: "莫纳什大学",
                su: "悉尼大学",
                unsw: "新南威尔士大学",
                nu: "国立大学",
                rmit: "墨尔本皇家理工大学",
                other: "其他大学"
            },
            favorite_major:{
                bs: "商科",
                st: "理工科",
                bio: "生物医学",
                edu: "教育",
                art: "艺术",
                other: "其他"
            },
            error_book: {
                first_name_missing: "名字必填",
                last_name_missing: "姓氏必填",
                gender_missing: "请选择性别",
                phone_number_missing: "电话号码必填",
                email_missing: "电子邮箱必填",
                grade_missing: "请选择年级",
                campus_missing: "请选择校区",
                school_missing: "学校必填",
                phone_number_invalid: "请填正确格式的电话号码",
                email_invalid: "请填正确格式的电子邮箱",
                phone_number_duplicate: "在此申请中多于一位同学填写了电话号码<>",
                email_duplicate: "在此申请中多于一位同学填写了电子邮箱<>"
            },
            tab_title: {
                tab1: "教育科普讲座",
                tab2: "高考-大学讲座",
                tab3: "奖学金/AEAS讲座",
                tab4: "CASPer Test讲座",
            },
            form_labels: {
                first_name: "名字",
                last_name: "姓氏",
                ch_name: "中文名",
                gender: "性别",
                phone: "电话号码",
                email: "电子邮箱",
                wechat: "微信",
                grade: "年级",
                campus: "校区",
                subject: "感兴趣的科目",
                school: "学校",
                private_school: "准备申请的私立学校",
                add_student:"+ 添加学生",
                remove: "- 移除",
                favorite_uni: "心仪的大学",
                favorite_major: "心仪的专业",
                lecture_time: "参加讲座时间",
                hear_from: "您是如何了解到青藤的?",
                specify: "请说明",
                submit: "申请"
            }
        }
    }
];

let news_content = [
    {
        version: "en",
        content: {
            news_title: "News",
            video_title: "Videos",
            expand_btn_text:"Show More",
            collapse_btn_text:"Show Less",
            news_list: [
                {
                    id: 1,
                    title: "Lorem ipsum 1",
                    img: "/news.png",
                    paragraphs: [
                        {
                            id: 1,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                            img: {
                                path: "/news.png",
                                position: "after"
                            }
                        },
                        {
                            id: 2,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                            img: {
                                path: "/news.png",
                                position: "right"
                            }
                        }
                    ]
                },
                {
                    id: 2,
                    title: "Lorem ipsum 2",
                    img: "/news.png",
                    paragraphs: [
                        {
                            id: 1,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                            img: {
                                path: "/news.png",
                                position: "after"
                            }
                        },
                        {
                            id: 2,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                            img: {
                                path: "/news.png",
                                position: "right"
                            }
                        }
                    ]
                },
                {
                    id: 3,
                    title: "Lorem ipsum 3",
                    img: "/news.png",
                    paragraphs: [
                        {
                            id: 1,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                            img: {
                                path: "/news.png",
                                position: "after"
                            }
                        },
                        {
                            id: 2,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                            img: {
                                path: "/news.png",
                                position: "right"
                            }
                        }
                    ]
                },
                {
                    id: 4,
                    title: "Lorem ipsum 4",
                    img: "/news.png",
                    paragraphs: [
                        {
                            id: 1,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                            img: {
                                path: "/news.png",
                                position: "after"
                            }
                        },
                        {
                            id: 2,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
                            img: {
                                path: "/news.png",
                                position: "right"
                            }
                        },
                        {
                            id: 3,
                            text: "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                        }
                    ]
                }
            ],
            videos: [
                {
                    title: "West World 1",
                    embedId: "LXZrQA2Ebkk"
                },
                {
                    title: "West World 2",
                    embedId: "cIsAsFmYUR8"
                },
                {
                    title: "West World 3",
                    embedId: "HNMt_6GgHek"
                },
                {
                    title: "West World 4",
                    embedId: "ihcXKSg2i8s"
                },
                {
                    title: "West World 5",
                    embedId: "RDhph-aBF6k"
                }
            ]
        }
    },
    {
        version: "ch",
        content: {
            news_title: "新闻",
            video_title: "视频",
            expand_btn_text:"显示更多",
            collapse_btn_text:"显示更少",
            news_list: [
                {
                    id: 1,
                    title: "新闻1",
                    img: "/news.png",
                    paragraphs: [
                        {
                            id: 1,
                            text: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光",
                            img: {
                                path: "/news.png",
                                position: "after"
                            }
                        },
                        {
                            id: 2,
                            text: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光",
                            img: {
                                path: "/news.png",
                                position: "right"
                            }
                        }
                    ]
                },
                {
                    id: 2,
                    title: "新闻2",
                    img: "/news.png",
                    paragraphs: [
                        {
                            id: 1,
                            text: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光",
                            img: {
                                path: "/news.png",
                                position: "after"
                            }
                        },
                        {
                            id: 2,
                            text: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光",
                            img: {
                                path: "/news.png",
                                position: "right"
                            }
                        }
                    ]
                },
                {
                    id: 3,
                    title: "新闻3",
                    img: "/news.png",
                    paragraphs: [
                        {
                            id: 1,
                            text: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光",
                            img: {
                                path: "/news.png",
                                position: "after"
                            }
                        },
                        {
                            id: 2,
                            text: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光",
                            img: {
                                path: "/news.png",
                                position: "right"
                            }
                        }
                    ]
                },
                {
                    id: 4,
                    title: "新闻4",
                    img: "/news.png",
                    paragraphs: [
                        {
                            id: 1,
                            text: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光",
                            img: {
                                path: "/news.png",
                                position: "after"
                            }
                        },
                        {
                            id: 2,
                            text: "天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光",
                            img: {
                                path: "/news.png",
                                position: "right"
                            }
                        },
                        {
                            id: 3,
                            text:"天地玄黄，宇宙洪荒，日月盈昃，辰宿列张，寒来暑往，秋收冬藏，闰余成岁，律吕调阳，云腾致雨，露结为霜，金生丽水，玉出昆冈，剑号巨阙，珠称夜光"
                        }
                    ]
                }
            ],
            videos: [
                {
                    title: "西部世界 1",
                    embedId: "LXZrQA2Ebkk"
                },
                {
                    title: "西部世界 2",
                    embedId: "cIsAsFmYUR8"
                },
                {
                    title: "西部世界 3",
                    embedId: "HNMt_6GgHek"
                },
                {
                    title: "西部世界 4",
                    embedId: "ihcXKSg2i8s"
                },
                {
                    title: "西部世界 5",
                    embedId: "RDhph-aBF6k"
                }
            ]
        }
    }
];

const courses_content = [
    {
        version: "en",
        courses: [
            {
                code: "math",
                title: "Mathmatics",
                primary: {
                    title: "Course satisfaction rate: 100%",
                    text: "",
                    labels: [
                        {
                            title: "400",
                            text: "Above 400 students had the raw score 40"
                        },
                        {
                            title: "20",
                            text: "We have more than 20 students who got the full mark in VCE exam"
                        },
                        {
                            title: "43",
                            text: "The average raw score is around 43 in every class."
                        }
                    ]
                },
                secondaries: [
                    {
                        title: "VCE Mathematics Specialty Course",
                        text: "",
                        features: [
                            {
                                title: "Efficient teaching in small classes",
                                text: ""
                            },
                            {
                                title: "VCE examiner grading",
                                text: ""
                            },
                            {
                                title: "Targeted mock exams and practices",
                                text: ""
                            }
                        ]
                    },
                    {
                        title: "VCE Mathematical",
                        text: "",
                        features: [
                            {
                                title: "Further Math",
                                text: "Although Further Math seem simple, but in fact it focuses on students' understanding of the language, most local students will choose this subject, because Further Math are simple, so this subject scales down in ATAR, the competition pressure is high, so if students want to get a high score, there are high requirements for students' logic, and language."
                            },
                            {
                                title: "Mathematical Methods",
                                text: "Most students will choose Mathematics Methods, and the difficulty factor is medium. Although it is easier than Specialist Math , with the trend of questions in recent years, the exam questions have become more and more difficult. Students need to practice more questions to improve the ability to draw inferences. This subject will scale up 5 points, which is a good choice for students."
                            },
                            {
                                title: "Specialist Math",
                                text: "The difficulty level of Specialist Math is the highest, few students will choose this course, and the competitive pressure is relatively low. The extra points are around 10 points. Its way of coming up with questions is straightforward, so once you can understand the concept in the early stage, it is not so difficult to solve the questions in the later stage."
                            }
                        ]
                    }
                ]
            },
            {
                code: "en",
                title: "EAL&English",
                primary: {
                    title: "Course satisfaction 100%",
                    text: "",
                    labels:[]
                },
                secondaries: [
                    {
                        title: "EAL & English Specialty Course",
                        features: [
                            {
                                title: "Efficient teaching in small classes",
                                text: " Group according to students' differences, and needs, which is conducive to teachers to provide targeted tutoring according to students' differences, and at the same time, students with the same hobbies and interests can help each other, which is conducive to the cultivation of cooperation spirit. In the classroom teaching of small class teaching, teachers can pay more attention to each student, according to the student's learning situation, teachers can adjust at any time, each student's learning situation teachers know well, and for different students to provide effective guidance, so that students learn more solid knowledge and improve their ability."
                            },
                            {
                                title: "VCE examiner grading",
                                text: "The teacher will combine the focus of the annual exam to transform the English composition, expand the knowledge of students, and make  professional mock  papers, so that students can fully feel confident in this year's exam. Our examination questions have been used in the college entrance for many times and are deeply trusted by students."
                            },
                            {
                                title: "Extra practices and mock exams",
                                text: "Tsingteng provides students with a variety of thematic assault courses, students can carry out targeted practice on their weak points, keep up with real-time, and be familiar with the three major question types of English (listening, language analysis, text response) and gradually can make inferences. Eliminate learning blind spots so students get grades that mee"
                            }
                        ]
                    }
                ]
            },
            {
                code: "ch",
                title: "VCE Science",
                primary: {
                    title: "Course satisfiaction 100%",
                    text: "",
                    labels:[]
                },
                secondaries: [
                    {
                        title: "VCE science special courses",
                        features: [
                            {
                                title: "Efficient teaching in small classes",
                                text: " Group according to students' differences, interests and needs, which is conducive to teachers to provide targeted tutoring according to students' differences, and at the same time, students with the same hobbies and interests can help each other, which is conducive to the cultivation of cooperation spirit. In the classroom teaching of small class teaching, teachers can pay more attention to each student, according to the student's learning situation, teachers can adjust at any time, each student's learning situation teachers know well, and for different students to provide effective guidance, so that students learn more solid knowledge and improve their ability."
                            },
                            {
                                title: "VCE examiner grading",
                                text: "Due to the rich teaching experience, the teachers will combine the focus of the annual exam to deform the sceince problems, expand the knowledge of students, and produce professional mock papers, so that students can fully feel confident in this year's exam. Our examination questions have been used in the college entrance examination for many times and are deeply trusted by students."
                            }
                        ]
                    }
                ]
            },
            {
                code: "ib",
                title: "IBDP",
                primary: {
                    title: "Course satisfaction rate: 100%",
                    text: "The IB program, or International Baccalaureate Organization (IBO), is a curriculum from kindergarten to university preparation for students around the world. A college preparatory program for high school students in Year 11 and 12. The IB curriculum is not based on the curriculum system of any country in the world and is self-contained, and widely absorbs the advantages of the mainstream curriculum system of many contemporary developed countries, covering its main core content. Therefore, the IB curriculum system has compatibility with mainstream education curriculum systems around the world, and has its own uniqueness under the development of educational concepts. The IB curriculum is divided into six foundational subject areas, with students studying both science and humanities. All students enrolled in the Diploma Programme must take one course in each of the six subject groups, and the Arts and Third Language in Group 6 can choose an additional subject from the Social Sciences Group III or the Natural Sciences in Group IV if students do not have an option. Each course is further divided into advanced courses and general courses. The IB programme is a challenging and highly recognized relatively difficult programme that prepares students for university studies and an International Baccalaureate Certificate. After obtaining this certificate, students obtain a direct qualification to study at UK universities and most other national universities.",
                    labels:[]
                },
                secondaries: [
                ]
            },
            {
                code: "el",
                title: "IELTS&PTE",
                primary: {
                    text: "IELTS: International English Language Testing System (IELTS), is one of the famous international standardized English proficiency tests. The international test for comprehensive assessment of English ability from the four aspects of listening, speaking, reading and writing, can accurately assess the candidate's English language use ability in a three-dimensional and comprehensive way, and the international English test with a high degree of global recognition, whether it is Australia or New Zealand, all local institutions 100% recognize and prefer IELTS, IELTS is also the earliest widely recognized language test in Australian and New Zealand immigration or other non-immigrant applications. IELTS has always been recognised in Australia and New Zealand as unmatched by other exams, so IELTS is the best choice for studying, immigrating and working in Australia and New Zealand. \n\nPTE: Pearson Tests of English is a series of English proficiency tests developed by Pearson Language Test Center, and PTE Academic English is mainly suitable for students who want to study in English-speaking countries to provide proof of language proficiency. Each exam consists of four aspects: listening, speaking, reading and writing. The PTE English Test is a certification test of English language proficiency for students whose first language is not English. It examines candidates' English ability from four aspects: listening, speaking, reading and writing, and gives fair judgment, and is awarded an international certificate by the National Qualifications and Vocational Qualifications Examination Board of the United Kingdom. PTE focuses on communicative ability task-based examination, with advanced marking system, unique test topics, high anti-counterfeiting technology certificate, targeted score feedback, perfect global examination report and many other characteristics, is a truly international English language proficiency certification.",
                    labels:[]
                },
                secondaries: [
                    {
                        title: "There are three unique  features for the Tsingteng language test courses.",
                        features: [
                            {
                                title: "Efficient teaching in small classes",
                                text: " Group according to students' differences, and needs, which is conducive to teachers to provide targeted tutoring according to students' differences, and at the same time, students with the same hobbies and interests can help each other, which is conducive to the cultivation of cooperation spirit. In the classroom teaching of small class teaching, teachers can pay more attention to each student, according to the student's learning situation, teachers can adjust at any time, each student's learning situation teachers know well, and for different students to provide effective guidance, so that students learn more solid knowledge and improve their ability."
                            },
                            {
                                title: "VCE examiner grading",
                                text: "The teacher will combine the focus of the annual exam to transform the English composition, expand the knowledge of students, and make  professional mock  papers, so that students can fully feel confident in this year's exam. Our examination questions have been used in the college entrance for many times and are deeply trusted by students."
                            },
                            {
                                title: "Extra practices and mock exams",
                                text: "Tsingteng provides students with a variety of thematic assault courses, students can carry out targeted practice on their weak points, keep up with real-time, and be familiar with the three major question types of English (listening, language analysis, text response) and gradually can make inferences. Eliminate learning blind spots so students get grades that mee"
                            }
                        ]
                    }
                ]
            },
            {
                code: "uni",
                title: "University Tutorials",
                primary: {
                    title: "Course pass rate: 100%",
                    text: "For college students who have been admitted to Melbourne, Tsingteng Education provides university course support, teachers are from top universities in Australia, to help students understand the university content in advance, relieve anxiety, we use the original courseware teaching, synchronous learning process, keep up with the teaching progress, so that each lesson does not leave doubts, to help students from pre-class preview to after-class homework one-stop exclusive tutoring, solve academic problems.",
                    labels:[]
                },
                secondaries: [
                    {
                        title: "Australia / China job guide",
                        text:"",
                        features: []
                    }
                ]
            },
            {
                code: "other",
                title: "Other Courses"
            }
        ]
    },
    {
        version: "ch",
        courses: [
            {
                code: "math",
                title: "数学",
                primary: {
                    title: "课程满意率100%",
                    text: "",
                    labels: [
                        {
                            title: "400",
                            text: "400+学生裸分40分以上"
                        },
                        {
                            title: "20",
                            text: "20+数学VCE状元裸分满分"
                        },
                        {
                            title: "43",
                            text: "全班平均裸分43以上"
                        }
                    ]
                },
                secondaries: [
                    {
                        title: "VCE数学特色课程",
                        text: "",
                        features: [
                            {
                                title: "精品小班高效授课",
                                text: "根据学生的差异、兴趣爱好和需求进行分组，这样有利于教师根据学生的不同有针对性的辅导，同时，有相同爱好兴趣的同学可以相互帮助，相互沟通，有利于合作精神的培养。分组后要注意学生情况的动态变化，看情况变化而随时调整分组;教师深入小组对学生进行个别指导，小班化可以加强知识与能力、情感、态度、价值观之间的联系，在小班化教学的课堂教学上，教师能更多的关注每一位学生，针对学生的学习状况，教师随时进行调节，每一个学生的学习状况教师了如指掌，并针对不同的学生进行有效辅导，使学生学习知识更扎实、更丰富，能力得到提高;由于学生人数少，小班化教学的模式能更好的考虑到学生的个体差异，更好的开展因材施教;小班化教学的学生人数少，相对每个学生占用的学习空间、活动空间和其它教育资源占有量比较大"
                            },
                            {
                                title: "名师出题保质保量",
                                text: " 让学生合理既定目标大学，并对达成目标所需具备的能力、条件进行详细分析。以目标为基准，倒推时间轴，把每一个学习计划都落实到具体时间节点，教学负责人、授课教师、学管师多重管理模式。建立科学教学体系、后勤管理体系、学生管理体系，以保障学生身心健康与成绩齐发展，专业师资团队，多年专注探索教研,打破传统课堂单一授课模式局限。提倡有温度的教学，强调氛围的力量，激发学生学习循环原动力。环环相扣的行为教学，来回检测学生掌握情况，消除学习盲点让学生获得符合预期的成绩。学生受教育的时间得到了改善，课堂上学生获得更多的创造的空间和时间，能充分地发挥主体作用，能更自主地表达自己的看法，开展能动的创造。"
                            },
                            {
                                title: "课外辅导锦上添花",
                                text: "信息网络技术作为传播载体，有其便捷性和高效性。课外指导策略可充分结合网络的便捷性，有效培养学生浓厚的兴趣。利用手机，在班级微信群进行有效的交流互动，从而使学生主动读书、爱上读书。通过学生主动参与学习的过程，其兴趣得到有效的激发，学生对文字的理解能力以及鉴赏能力得到有效的提升。"
                            }
                        ]
                    },
                    {
                        title: "VCE数学可选科目",
                        text: "课程包含内部冲刺模拟考试，预测准确率在80%以上",
                        features: [
                            {
                                title: "VCE低数",
                                text: "低数的主要学习内容分为两个必修模块和四个选修模块。两个必修模块分别是数据统计与金融数学。其中，数据统计是唯一一个三门数学中都有的知识点，但在低数中学习和考察的部分也是最基础的。数据统计在低数的学习中非常依赖计算器的操作，尤其是表格数据的统计和分析以及统计图的绘制将会完全依赖计算器。同时，低数的金融数学模块也会大量用到计算器。做一道金融数学的大题，需要处理的数字往往错综复杂。若要不出错，就一定要熟练掌握计算器中金融计算的功能。而四个选修模块，又分别为矩阵、图论、几何测量与图形关系。"
                            },
                            {
                                title: "VCE中数",
                                text: "中数的知识内容则要统一很多。由于将众多其他分支安排在了低数内，中数的教学目标主要是为微积分的登堂入室打下知识基础。因此，除了微积分本身，中数的其他学习内容就只有函数和概率统计，然而这两个课题也在知识内容上被很快的衔接入微积分的体系当中。所以基本可以说，中数就是一个微积分为王的科目，而又因为只有这一个主要内容，考试题目往往出得比较深。因此，中数对数学思维和逻辑思考的要求，可以说是三门数学中最高的。"
                            },
                            {
                                title: "VCE高数",
                                text: "维州考试局规定，要报考高数的前提要求是必须同时报考或者已学完中数。纵始如此，微积分的学习只是高数学习内容的一部分。很多高数中独有的知识，例如复数、向量、解析几何，是VCE其他数学科目中完全没有的。因此，很多中数成绩很好的同学，面对高数庞杂的知识内容也难免会感到无所适从。与中数类似，高数的学习在一二单元 (Unit 1 & 2，指代课标的11年级学习内容) 和三四单元 (Unit 3 & 4，指代课标的12年级学习内容) 基本上相同。高等数学的教学内容主要分为微积分这个核心内容和其他六个内容，分别是复数 (Complex Number)、向量 (Vectors)、三角函数 (Circular Functions)、解析几何 (Coordinate Geometry)、运动力学 (Kinematics and Dynamics) 和概率统计 (Probability and Statistics)。因此，高数之难，主要是在于知识模块众多，而且不仅每个知识本身的难度都不低，知识模块之间还经常会混起来组成难度更大的考点。"
                            }
                        ]
                    }
                ]
            },
            {
                code: "en",
                title: "EAL和English",
                primary: {
                    title: "课程通过率100%",
                    text: "",
                    labels:[]
                },
                secondaries: [
                    {
                        title: "EAL和English特色课程",
                        text: "",
                        features: [
                            {
                                title: "精品小班高效授课",
                                text: "根据学生的差异、兴趣爱好和需求进行分组，这样有利于教师根据学生的不同有针对性的辅导，同时，有相同爱好兴趣的同学可以相互帮助，相互沟通，有利于合作精神的培养。分组后要注意学生情况的动态变化，看情况变化而随时调整分组;教师深入小组对学生进行个别指导，小班化可以加强知识与能力、情感、态度、价值观之间的联系，在小班化教学的课堂教学上，教师能更多的关注每一位学生，针对学生的学习状况，教师随时进行调节，每一个学生的学习状况教师了如指掌，并针对不同的学生进行有效辅导，使学生学习知识更扎实、更丰富，能力得到提高;由于学生人数少，小班化教学的模式能更好的考虑到学生的个体差异，更好的开展因材施教;小班化教学的学生人数少，相对每个学生占用的学习空间、活动空间和其它教育资源占有量比较大"
                            },
                            {
                                title: "名师出题保质保量",
                                text: "让学生合理既定目标大学，并对达成目标所需具备的能力、条件进行详细分析。以目标为基准，倒推时间轴，把每一个学习计划都落实到具体时间节点，教学负责人、授课教师、学管师多重管理模式。建立科学教学体系、后勤管理体系、学生管理体系，以保障学生身心健康与成绩齐发展，专业师资团队，多年专注探索教研,打破传统课堂单一授课模式局限。提倡有温度的教学，强调氛围的力量，激发学生学习循环原动力。环环相扣的行为教学，来回检测学生掌握情况，消除学习盲点让学生获得符合预期的成绩。学生受教育的时间得到了改善，课堂上学生获得更多的创造的空间和时间，能充分地发挥主体作用，能更自主地表达自己的看法，开展能动的创造。"
                            },
                            {
                                title: "课外辅导锦上添花",
                                text: "信息网络技术作为传播载体，有其便捷性和高效性。课外指导策略可充分结合网络的便捷性，有效培养学生浓厚的兴趣。利用手机，在班级微信群进行有效的交流互动，从而使学生主动读书、爱上读书。通过学生主动参与学习的过程，其兴趣得到有效的激发，学生对文字的理解能力以及鉴赏能力得到有效的提升。"
                            }
                        ]
                    }
                ]
            },
            {
                code: "ch",
                title: "VCE科学",
                primary: {
                    title: "课程通过率100%",
                    text: "",
                    labels:[]
                },
                secondaries: [
                    {
                        title: "VCE科学特色课程",
                        text: "",
                        features: [
                            {
                                title: "精品小班高效授课",
                                text: "根据学生的差异、兴趣爱好和需求进行分组，这样有利于教师根据学生的不同有针对性的辅导，同时，有相同爱好兴趣的同学可以相互帮助，相互沟通，有利于合作精神的培养。分组后要注意学生情况的动态变化，看情况变化而随时调整分组;教师深入小组对学生进行个别指导，小班化可以加强知识与能力、情感、态度、价值观之间的联系，在小班化教学的课堂教学上，教师能更多的关注每一位学生，针对学生的学习状况，教师随时进行调节，每一个学生的学习状况教师了如指掌，并针对不同的学生进行有效辅导，使学生学习知识更扎实、更丰富，能力得到提高;由于学生人数少，小班化教学的模式能更好的考虑到学生的个体差异，更好的开展因材施教;小班化教学的学生人数少，相对每个学生占用的学习空间、活动空间和其它教育资源占有量比较大"
                            },
                            {
                                title: "名师出题保质保量",
                                text: "让学生合理既定目标大学，并对达成目标所需具备的能力、条件进行详细分析。以目标为基准，倒推时间轴，把每一个学习计划都落实到具体时间节点，教学负责人、授课教师、学管师多重管理模式。建立科学教学体系、后勤管理体系、学生管理体系，以保障学生身心健康与成绩齐发展，专业师资团队，多年专注探索教研,打破传统课堂单一授课模式局限。提倡有温度的教学，强调氛围的力量，激发学生学习循环原动力。环环相扣的行为教学，来回检测学生掌握情况，消除学习盲点让学生获得符合预期的成绩。学生受教育的时间得到了改善，课堂上学生获得更多的创造的空间和时间，能充分地发挥主体作用，能更自主地表达自己的看法，开展能动的创造。"
                            },
                            {
                                title: "课外辅导锦上添花",
                                text: "信息网络技术作为传播载体，有其便捷性和高效性。课外指导策略可充分结合网络的便捷性，有效培养学生浓厚的兴趣。利用手机，在班级微信群进行有效的交流互动，从而使学生主动读书、爱上读书。通过学生主动参与学习的过程，其兴趣得到有效的激发，学生对文字的理解能力以及鉴赏能力得到有效的提升。"
                            }
                        ]
                    }
                ]
            },
            {
                code: "ib",
                title: "IBDP",
                primary: {
                    title: "课程满意率100%",
                    text: "",
                    labels:[]
                },
                secondaries: []
            },
            {
                code: "el",
                title: "雅思和PTE",
                primary: {
                    title:"",
                    text: "",
                    labels:[]
                },
                secondaries: [
                    {
                        title: "青藤语言考试类课程有三大杀手锏",
                        features: [
                            {
                                title: "精品小班高效授课",
                                text: "根据学生的差异、兴趣爱好和需求进行分组，这样有利于教师根据学生的不同有针对性的辅导，同时，有相同爱好兴趣的同学可以相互帮助，相互沟通，有利于合作精神的培养。分组后要注意学生情况的动态变化，看情况变化而随时调整分组;教师深入小组对学生进行个别指导，小班化可以加强知识与能力、情感、态度、价值观之间的联系，在小班化教学的课堂教学上，教师能更多的关注每一位学生，针对学生的学习状况，教师随时进行调节，每一个学生的学习状况教师了如指掌，并针对不同的学生进行有效辅导，使学生学习知识更扎实、更丰富，能力得到提高;由于学生人数少，小班化教学的模式能更好的考虑到学生的个体差异，更好的开展因材施教;小班化教学的学生人数少，相对每个学生占用的学习空间、活动空间和其它教育资源占有量比较大"
                            },
                            {
                                title: "名师出题保质保量",
                                text: "让学生合理既定目标大学，并对达成目标所需具备的能力、条件进行详细分析。以目标为基准，倒推时间轴，把每一个学习计划都落实到具体时间节点，教学负责人、授课教师、学管师多重管理模式。建立科学教学体系、后勤管理体系、学生管理体系，以保障学生身心健康与成绩齐发展，专业师资团队，多年专注探索教研,打破传统课堂单一授课模式局限。提倡有温度的教学，强调氛围的力量，激发学生学习循环原动力。环环相扣的行为教学，来回检测学生掌握情况，消除学习盲点让学生获得符合预期的成绩。学生受教育的时间得到了改善，课堂上学生获得更多的创造的空间和时间，能充分地发挥主体作用，能更自主地表达自己的看法，开展能动的创造。"
                            },
                            {
                                title: "课外辅导锦上添花",
                                text: "信息网络技术作为传播载体，有其便捷性和高效性。课外指导策略可充分结合网络的便捷性，有效培养学生浓厚的兴趣。利用手机，在班级微信群进行有效的交流互动，从而使学生主动读书、爱上读书。通过学生主动参与学习的过程，其兴趣得到有效的激发，学生对文字的理解能力以及鉴赏能力得到有效的提升。"
                            }
                        ]
                    }
                ]
            },
            {
                code: "uni",
                title: "大学课程辅导",
                primary: {
                    title: "课程通过率100%",
                    text: "",
                    labels:[]
                },
                secondaries: [
                    {
                        title: "澳洲/中国求职指导",
                        text: "",
                        features: [
                            {
                                title: "精品小班高效授课",
                                text: "根据学生的差异、兴趣爱好和需求进行分组，这样有利于教师根据学生的不同有针对性的辅导，同时，有相同爱好兴趣的同学可以相互帮助，相互沟通，有利于合作精神的培养。分组后要注意学生情况的动态变化，看情况变化而随时调整分组;教师深入小组对学生进行个别指导，小班化可以加强知识与能力、情感、态度、价值观之间的联系，在小班化教学的课堂教学上，教师能更多的关注每一位学生，针对学生的学习状况，教师随时进行调节，每一个学生的学习状况教师了如指掌，并针对不同的学生进行有效辅导，使学生学习知识更扎实、更丰富，能力得到提高;由于学生人数少，小班化教学的模式能更好的考虑到学生的个体差异，更好的开展因材施教;小班化教学的学生人数少，相对每个学生占用的学习空间、活动空间和其它教育资源占有量比较大"
                            },
                            {
                                title: "名师出题保质保量",
                                text: "让学生合理既定目标大学，并对达成目标所需具备的能力、条件进行详细分析。以目标为基准，倒推时间轴，把每一个学习计划都落实到具体时间节点，教学负责人、授课教师、学管师多重管理模式。建立科学教学体系、后勤管理体系、学生管理体系，以保障学生身心健康与成绩齐发展，专业师资团队，多年专注探索教研,打破传统课堂单一授课模式局限。提倡有温度的教学，强调氛围的力量，激发学生学习循环原动力。环环相扣的行为教学，来回检测学生掌握情况，消除学习盲点让学生获得符合预期的成绩。学生受教育的时间得到了改善，课堂上学生获得更多的创造的空间和时间，能充分地发挥主体作用，能更自主地表达自己的看法，开展能动的创造。"
                            },
                            {
                                title: "课外辅导锦上添花",
                                text: "信息网络技术作为传播载体，有其便捷性和高效性。课外指导策略可充分结合网络的便捷性，有效培养学生浓厚的兴趣。利用手机，在班级微信群进行有效的交流互动，从而使学生主动读书、爱上读书。通过学生主动参与学习的过程，其兴趣得到有效的激发，学生对文字的理解能力以及鉴赏能力得到有效的提升。"
                            }
                        ]
                    }
                ]
            },
            {
                code: "other",
                title: "其他课程"
            }
        ]
    }
]

const join_content = [
    {
        version: "en",
        content: {
            title: "We want you",
            welfare_title: "Welfare Treatment",
            job_app_title: "Job Application",
            positions:[
                {
                    name:"Teachers of all grades",
                    description: "•Strong ability, understand VCE exam requirements and high score points in the exam.\n•Prefer applicant who have taken the VCE exam and have a higher raw score in the specific subjects\n•Prefer applicants who have teaching experience related to the subject.\n•Strong communication and expression skills, and clear mindsets.\n•Be able to teach according to personal situation and teach interactively\n•Have passion for teaching, clear curriculum planning and targeted teaching methods\n•Good at teamwork, constantly improving themselves from teaching and learning ",
                    img: "/jpinUseJS.png"
                },
                {
                    name:"Administrative assistant",
                    description: "•Proficient in text editing, typesetting and office software applications\n•Have a strong sense of responsibility and fairness, strong team leadership ability, good communication skills, adaptability and proficiency in human resources professional skills\n•Have the vision of discovering talents, have the ability to quickly judge the characteristics of talents, and have strong affinity\n•Communicate and coordinate with various departments, and provide logistical support\n \n ",
                    img: "/jpinUseXZ.png"
                },
                {
                    name:"Financial assistant",
                    description: "•Implement the company's financial system and assist department managers to jointly do a good job in corporate financial management\n•According to the company's financial regulations, carry out daily financial accounting, account processing, scientifically set up accounts, strictly review and reasonably compile vouchers, the rationality of various expenses payment, and the scientific processing of accounts\n•Carry out financial cost control accounting, unit project allocation settlement and review, including various current account verification, clearing processing, to ensure that the accounts are consistent and the account certificates are consistent.",
                    img: "/jpinUseCW.png"
                },
                {
                    name:"Marketing assistant",
                    description: "•Proficient in office software\n•Ability to work independently and as a team player\n•Strong expression, understanding and public relations skills\n•Proactive, cheerful, efficient, willing to overcome challenges\n \n \n \n \n ",
                    img: "/jpinUseSC.png"
                }
            ],
            welfare:[
                {
                    icon: "fas fa-dollar-sign",
                    text: "Competitive salary"
                },
                {
                    icon: "fas fa-thumbs-up",
                    text: "Systematic training to improve ability"
                },
                {
                    icon: "fas fa-clipboard-check",
                    text: "Perfect promotion mechanismy"
                },
                {
                    icon: "fas fa-rocket",
                    text: "Bright career prospects"
                }
            ],
            genders: {
                none: "",
                male: "Male",
                female: "Female",
            },
            hear_from: {
                classmates: "Classmates",
                friends: "Friends/Relatives",
                yeeyi:"Yeeyi",
                indeed: "Indeed",
                gumtree: "Gumtree",
                seek: "Seek",
                wechat: "Wechat moments",
                internet: "Internet Search",
                newspaper: "Newspaper",
                seminar: "Seminar",
                other: "Other"
            },
            error_book: {
                applicant_name_missing: "Applicant's name is mandatoy",
                mobile_missing: "Mobile number is mandatory",
                mobile_invalid: "Invalid mobile number",
                email_missing: "Emial address is mandatory",
                email_invalid: "Invalid email address",
                gender_missing: "Gender must be specified",
                dob_missing: "Date of Birth is mandatory",
                dob_invalid: "Invalid Date of Birth",
                position_missing: "Position is mandatory",
                subject_missing: "Subject is mandatory"
            },
            form_labels: {
                applicant_name: "Applicant Name",
                applicant_ch_name: "Chinese Name",
                mobile: "Mobile",
                email: "Email",
                social_media: "Wechat/QQ",
                gender: "Gender",
                dob: "Date of Birth (DD/MM/YYYY)",
                position: "Position",
                subject: "Subject",
                specified_subject: "Please specify subject",
                education_background: "Education Background",
                period: "Period",
                collage: "Collage",
                qualification: "Qualification",
                major: "Major",
                add_edu: "+ Add Education Background",
                working_experience: "Working Experience",
                employer: "Employer",
                pre_position: "Position",
                add_experience: "+ Add Working Experience",
                remove: "- Remove",
                hear_from: "Where did you get to know Tsing Tung?",
                submit:"Submit"
            }
        }
    },
    {
        version: "ch",
        content: {
            title: "我们需要你",
            welfare_title: "福利待遇",
            job_app_title: "职位申请",
            positions:[
                {
                    name:"各科老师",
                    description: "业务能力强，了解所授学科VCE考试要求和高分点参加过VCE考试并且所授科目裸分较高优先有所授学科相关的教学经历优先沟通表达能力强，讲课条理清晰能够因材施教，互动教学对教学有热情，有详细课程规划和针对性教学方法善于团队合作，在工作中学习和不断自我提高",
                    img: "/jpinUseJS.png"
                },
                {
                    name:"行政助理",
                    description: "能熟练的掌握文字编辑、排版和办公软件的应用；具有较强的责任意识和公正性，有较强的团队领导能力、良好的沟通能力、应变能力以及熟练掌握人力资源专业技能；有发现人才的眼光、有快速判断人才特点的能力，具有较强的亲和力；上传下达，与各部门沟通协调，提供后勤保障",
                    img: "/jpinUseXZ.png"
                },
                {
                    name:"财务助理",
                    description: "执行公司财务制度，协助部门经理共同搞好企业财务管理工作；依据公司财务规定，进行日常财务核算，帐务处理工作，科学设置帐户，严格审核和合理编制凭证，确保每笔业务正确性，各项费用支付合理性，帐户处理科学性；进行财务成本控制核算，单位工程分配结算和审核，包括各种往来帐务核对、核查、清算处理，确保帐帐相符，帐证相符，帐表相符",
                    img: "/jpinUseCW.png"
                },
                {
                    name:"市场助理",
                    description: "熟练操作办公软件；独立工作能力和团队合作精神；具有较强的表达、理解与公关能力；积极主动、性格开朗、讲效率、乐于接受挑战",
                    img: "/jpinUseSC.png"
                }
            ],
            welfare:[
                {
                    icon: "fas fa-dollar-sign",
                    text: "具有竞争力的薪资"
                },
                {
                    icon: "fas fa-thumbs-up",
                    text: "系统培训提升能力"
                },
                {
                    icon: "fas fa-clipboard-check",
                    text: "完善的晋升机制"
                },
                {
                    icon: "fas fa-rocket",
                    text: "光明的职业发展前景"
                }
            ],
            genders: {
                none: "",
                male: "男",
                female: "女",
            },
            hear_from: {
                classmates: "同学",
                friends: "亲朋好友",
                yeeyi:"亿亿网",
                indeed: "Indeed",
                gumtree: "Gumtree",
                seek: "Seek",
                wechat: "微信朋友圈",
                internet: "网上搜索",
                newspaper: "报纸",
                seminar: "讲座",
                other: "其他"
            },
            error_book: {
                applicant_name_missing: "申请人姓名必填",
                mobile_missing: "手机号码必填",
                mobile_invalid: "请填正确格式的手机号码",
                email_missing: "电子邮箱必填",
                email_invalid: "请填正确格式的电子邮箱地址",
                gender_missing: "性别必填",
                dob_missing: "生日必填",
                dob_invalid: "I请填正确格式的生日日期",
                position_missing: "职位必填",
                subject_missing: "科目必填"
            },
            form_labels: {
                applicant_name: "申请人姓名",
                applicant_ch_name: "中文名",
                mobile: "电话",
                email: "电子邮箱",
                social_media: "微信/QQ",
                gender: "性别",
                dob: "出生日期 (DD/MM/YYYY)",
                position: "应聘职位",
                subject: "科目",
                specified_subject: "请说明具体科目",
                education_background: "教育背景",
                period: "时间",
                collage: "毕业院校",
                qualification: "学历",
                major: "专业",
                add_edu:"添加教育背景",
                working_experience: "工作经历",
                employer: "公司",
                pre_position: "职务",
                add_experience: "添加工作经历",
                remove: "移除",
                hear_from: "您是如何了解到青藤的？",
                submit:"申请"
            }
        }
    }
];

const contact_content = [
    {
        version: "en",
        content: {
            title: "Contact Us"
        }
    },
    {
        version: "ch",
        content: {
            title: "取得联系"
        }
    }
];

const score_list = [
    {
        name: "AB AC",
        atar: 99.95,
        school: "XYZ High School"
    },
    {
        name: "CD AB",
        atar: 99.95,
        school: "OPQ High School"
    },
    {
        name: "AC DC",
        atar: 99.90,
        school: "IJK High School"
    },
    {
        name: "DE TP",
        atar: 99.90,
        school: "TTT High School"
    },
    {
        name: "MN PQ",
        atar: 99.90,
        school: "XYZ High School"
    },
    {
        name: "YT SX",
        atar: 99.85,
        school: "ACF High School"
    },
    {
        name: "LT UF",
        atar: 99.85,
        school: "LKJ High School"
    },
    {
        name: "BD SK",
        atar: 99.85,
        school: "TIS High School"
    },
    {
        name: "ER UY",
        atar: 99.80,
        school: "LPI High School"
    },
    {
        name: "XZ CG",
        atar: 99.80,
        school: "RTS High School"
    },
];

const photo_gallaries = [
    {
        id: 1,
        photos: [
            {
                id: 1,
                img: "/Photo1.png"
            },
            {
                id: 2,
                img: "/Photo2.png"
            },
            {
                id: 3,
                img: "/Photo3.png"
            },
            {
                id: 4,
                img: "/Photo4.png"
            }
        ]
    },
    {
        id: 2,
        photos: [
            {
                id: 1,
                img: "/Photo5.png"
            },
            {
                id: 2,
                img: "/Photo6.png"
            },
            {
                id: 3,
                img: "/Photo7.png"
            },
            {
                id: 4,
                img: "/Photo8.png"
            }
        ]
    }
];

export {
    header_content,
    footer_content,
    home_content,
    about_content,
    lectures_content,
    news_content,
    courses_content,
    join_content,
    contact_content,
    score_list,
    photo_gallaries
};