import styled from 'styled-components';

const FontWrapper = styled.div`

    h3,h3,h5 {
        font: ${(props) =>props.version === "en"?'':'var(--chTitleFont)'};
    }

    h2{
        font-size: ${(props) =>props.version === "en"?'':"32px"};
    }

    h3 {
        font-size: ${(props) =>props.version === "en"?'':"28px"};
    }

    h5 {
        font-size: ${(props) =>props.version === "en"?'':"22px"};
    }

    p, a, label, input, button {
        font: ${(props) =>props.version === "en"?'':'var(--chTextFont)'}; 
    }
`;

export default FontWrapper