import { InfoConsumer } from '../Context';
import InfoSection from '../Sections/InfoSection';
import IntroductionSection from '../Sections/IntroductionSection';
import PhotoGallerySection from '../Sections/PhotoGallarySection';
import ScoreSection from '../Sections/Score_Section';
import { useState, useEffect } from 'react';
import useScoreList from '../../Hooks/useScoreList';
import GetScores from '../../Functions/GetScores';

const AboutUs = ({ lan }) => {
    const { scores, setScores } = useScoreList([]);

    const [resize, setResize] = useState(false);

    const handleResize = () => {
        if (window.innerWidth > 760)
            setResize(false);
        else
            setResize(true);
    };

    useEffect(() => {
        // const initializeScores = async () => {
        //     try{
        //         const fetched_score = (await GetScores()).map(score => {
        //             return {
        //                 name: score.name,
        //                 atar: score.atar,
        //                 school: score.school
        //             }
        //         });
        //         console.log(`scores 1: ${fetched_score}`);
        //         setScores(fetched_score);
        //     }
        //     catch(e){
        //         console.log(e.message);
        //     }
        // };

        // initializeScores().catch(console.err);
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);
    
    return (
        <div className="container">
            <div className="row mt5 d-flex justify-content-center">
                <InfoConsumer>
                    {
                        data => {
                            const content = data.about_content.find(a => a.version === lan).content;
                            const score_list = data.score_list.sort(({ atar: a }, { atar: b }) => b - a);
                            //const score_list = scores??[].sort(({ atar: a }, { atar: b }) => b - a);
                            const photo_gallaries = data.photo_gallaries;

                            return (
                                <>
                                    <IntroductionSection title={content.intr_section.title} text={content.intr_section.text} />
                                    <h2 className="mt-3 mb-5 text-center" >{content.score_section.title}</h2>
                                    <ScoreSection headers={content.score_section.headers} score_list={score_list} annual_performs={content.score_section.annual_performs} />
                                    <h2 className="mt-5 mb-5 text-center">{content.team_section.title}</h2>
                                    {
                                        content.team_section.teams.map(t =>
                                            <InfoSection img={t.img} title={t.title} text={t.text} imgPosition={t.img_position} />
                                        )
                                    }
                                    <MapView resize={resize} />
                                    {
                                        content.gallary_section.map(g =>
                                            <PhotoGallerySection title={g.title} photos={photo_gallaries.find(pg => pg.id === g.id).photos} />
                                        )
                                    }

                                </>
                            );
                        }
                    }
                </InfoConsumer>
            </div>
        </div>
    );
}

export default AboutUs;

const MapView = ({resize}) => {
    
    return (
        <div className="col-10 col-lg-12 max-auto mb-5 mr-0">
            <div className="card" >
                <div className="row card-body">
                    <iframe src="https://www.google.com/maps/d/embed?mid=11mDLlYBp2hfNYtuihDVsRSdTYFSwuM8&ehbc=2E312F" width={resize ? "320" : "640"} height={resize ? "240" : "480"}></iframe>
                </div>
            </div>
        </div>
    );

};

