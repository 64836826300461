import React from 'react';

const IntroductionSection = ({ title, text }) => {
    return (
            <div className="col-10 col-lg-12 max-auto mb-5 mr-0">
                <div className="card" >
                    <div className="row card-body">
                        <div className="col-sm-1" />
                        <div className="col-sm-5">
                            <h3 className="card-title mt-2 mb-5">
                                {title}
                            </h3>
                            <p className="card-text">
                                {text}
                            </p>
                        </div>
                        <div className="col-sm-6 mt-5 pt-5 h-100" />                    
                    </div>
                </div>
            </div>
    );
}

export default IntroductionSection;