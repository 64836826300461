
const GetToken = async() => {
    const res = await fetch(
        process.env.REACT_APP_AUTH0_URL,
        {
            method: "POST",
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
                audience: process.env.REACT_APP_AUTHO_AUDIENCE,
                grant_type: "client_credentials"
            })
        }
    );

    return res;
}

export default GetToken;