import GetToken from "./GetToken";

const GetCurrentSession = async (lecture) => {

    const result = await (await GetToken()).json();

    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/lecture/session/${lecture}`,
        {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${result.access_token}`
            }
        }
    );

    return res.json();
}

export default GetCurrentSession;