import { InfoConsumer } from '../Context';
import AchSection from '../Sections/AchSection';
import AdmissionSection from '../Sections/AdmissionSection';
import InfoSection from '../Sections/InfoSection';
import NewsSection from '../Sections/NewsSection';
import VideoSection from '../Sections/VideoSection';


const Home = ({ lan }) => {
    return (
        <div className="container">
            <div className="row mt5 d-flex justify-content-center">
                <InfoConsumer>
                    {
                        data => {
                            const content = data.home_content.find(h => h.version === lan).content;
                            const news_content = data.news_content.find(n => n.version === lan).content;
                            return (
                                <>
                                    <div
                                        style={
                                            {
                                                height: 400,
                                                width:"100vw",
                                                maxWidth:"100vw",
                                                margin:`0 calc(-50vw + 50%)`,
                                                backgroundRepeat:"no-repeat",
                                                backgroundSize: "cover",
                                                backgroundImage: `url("/${content.background}")`
                                            }
                                        }>
                                    </div>
                                    <h2 id="top" className="mt-3 mb-5 text-center" >{content.ach_section.title}</h2>
                                    {
                                        content.ach_section.achievements.map(a =>
                                            <AchSection key={a.id} logo={a.logo} title={a.title} text={a.text} />
                                        )
                                    }
                                    {
                                        <VideoSection title={content.video_section.title} embedId={content.video_section.embedId} />
                                    }
                                    <ToTop />
                                    {
                                        content.info_section.infos.map(i =>
                                            <InfoSection img={i.img} title={i.title} text={i.text} imgPosition={i.img_position} />
                                        )
                                    }
                                    <ToTop />
                                    <AdmissionSection title={content.admission_section.title} img={content.admission_section.img} />
                                    <ToTop />
                                    <h2 className="mt-5 mb-5 text-center">{content.news_section.title}</h2>
                                    {
                                        news_content.news_list.slice(0, 3).map(n =>
                                            <NewsSection id={n.id} img={n.img} title={n.title} />
                                        )
                                    }
                                    <ToTop />

                                </>
                            );
                        }
                    }
                </InfoConsumer>
            </div>
        </div>
    );
};

export default Home;

const ToTop = () => {
    return (
        <>
            <div className="col-10 col-lg-10 max-auto mb-2"></div>
            <div className="col-10 col-lg-2 max-auto mb-2">
                <a className="nav-link" href="#top">
                    <h5 className="text-center text-uppercase">
                        <i className="fas fa-arrow-alt-circle-up"></i>
                        To Top
                    </h5>
                </a>
            </div>
        </>
    );
}