import React, { useEffect, useState } from 'react';
import logo from '../../logo.svg';
import { Link } from 'react-router-dom';
import { InfoConsumer } from '../Context';
import styled from 'styled-components';

const Navbar = ({ handleCalc, handleContact, lan, handleLan }) => {
    const [show, setShow] = useState(false);

    const [resize, setResize] = useState(false);

    const handleResize = () => {
        if (window.innerWidth > 760)
            setResize(false);
        else
            setResize(true);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);

    return (
        <InfoConsumer>
            {
                data => {
                    const content = data.header_content.find(h => h.version === lan).content;
                    return (
                        <StyledNavbar>
                            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                                <div className="container-fluid">
                                    <Link className="navbar-brand ml-5" to="/">
                                        <img src={logo} alt="logo" style={{ width: '35px' }}></img>
                                    </Link>
                                    <button className="navbar-toggler" type="button"
                                        // data-bs-toggle="collapse" 
                                        // data-bs-target="#navbarSupportedContent" 
                                        // aria-controls="navbarSupportedContent" 
                                        // aria-expanded="false" 
                                        // aria-label="Toggle navigation"
                                        onClick={() => { if (show) setShow(false); else setShow(true); }}
                                    >
                                        <span>
                                            <i className='fas fa-bars' style={{ color: '#000' }} />
                                        </span>
                                    </button>
                                    <div className={`collapse navbar-collapse ${show ? "show" : ""}`} id="navbarSupportedContent">
                                        <ul className="navbar-nav m-auto">
                                            <li className="nav-item">
                                                <Link className="nav-link text-uppercase ml-5 mr-5" to="/" onClick={() => setShow(false)}>{content.home}&nbsp;<i className="fas fa-home"></i></Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link text-uppercase ml-5 mr-5" to="/aboutus" onClick={() => setShow(false)}>{content.about}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link text-uppercase ml-5 mr-5" to="/lectures" onClick={() => setShow(false)}>{content.lectures}</Link>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle text-uppercase" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {content.courses.text}
                                                </Link>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li><Link className="dropdown-item" to="/course/math" onClick={() => setShow(false)}>{content.courses.math}</Link></li>
                                                    <li><Link className="dropdown-item" to="/course/en" onClick={() => setShow(false)}>{content.courses.en}</Link></li>
                                                    <li><Link className="dropdown-item" to="/course/ch" onClick={() => setShow(false)}>{content.courses.ch}</Link></li>
                                                    <li><Link className="dropdown-item" to="/course/ib" onClick={() => setShow(false)}>{content.courses.ib}</Link></li>
                                                    <li><Link className="dropdown-item" to="/course/el" onClick={() => setShow(false)}>{content.courses.el}</Link></li>
                                                    <li><Link className="dropdown-item" to="/course/uni" onClick={() => setShow(false)}>{content.courses.uni}</Link></li>
                                                    <li><hr className="dropdown-divider"></hr></li>
                                                    <li><Link className="dropdown-item" to="/course/other" onClick={() => setShow(false)}>{content.courses.other}</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link text-uppercase ml-5 mr-5" to="/news" onClick={() => setShow(false)}>{content.news}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link text-uppercase ml-5 mr-5" to="/joinus" onClick={() => setShow(false)}>{content.join}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link text-uppercase ml-5 mr-5 " to={resize?"/contact":"#"} onClick={() => { if(!resize){handleContact();} setShow(false); }}>{content.contact}</Link>
                                            </li>
                                        </ul>
                                        <div className="d-flex" role="search">
                                            <button className="btn btn-success nav-btn" onClick={() => { handleLan(); setShow(false); }}>{content.lan}</button>
                                            {/* <button className="btn btn-success nav-btn" onClick={handleCalc}>{content.calc}</button> */}
                                            <Link className="btn btn-success nav-btn" to="/atar-calc" onClick={() => setShow(false)}>{content.calc}</Link>
                                        </div>
                                    </div>
                                    <div className="blur"></div>
                                </div>
                            </nav>
                        </StyledNavbar>
                    );
                }
            }
        </InfoConsumer>



    );
}

export default Navbar;

const StyledNavbar = styled.div`
    #navbarSupportedContent {
        z-index: 2000;
    }

    .nav-btn {
        color: var(--mainDarkGreen);
        background-color: var(--mainGreen);
        margin-left:10px;
        border:0;
    }

    .nav-btn:hover {
        color: var(--mainWhite);
        background-color: var(--mainDarkGreen);
    }

    .fixed-top{
        top:-10px !important;
    }

    .container-fluid{
        background-color: var(--mainWhite);
    }

    .nav-link {
        margin: 5px 10px 5px 10px;  
    }

    .nav-item {
        margin-top:10px;
        margin-left:10px;
        margin-right:10px;
        
    }

    .nav-item:hover {
        background-color: var(--mainDarkGreen);
        border-radius: 25px;
        .nav-link {
            color: var(--mainWhite) !important;
        }
    }

    .blur {
        position: fixed;
        top:50px;
        width: 100%;
        min-height: 50px;
        margin-bottom: 20px;
        background: rgba(255,255,255,.8);
        //z-index:1010;
        -webkit-filter: blur(10px);
        -moz-filter: blur(0px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
        filter: blur(10px);
    }

    @media all and (min-width: 992px) {
        .navbar .nav-item .dropdown-menu{ display: none; }
        .navbar .nav-item:hover .nav-link{   }
        .navbar .nav-item:hover .dropdown-menu{ display: block; }
        .navbar .nav-item .dropdown-menu{ margin-top:0; }
    }

    @media only screen and (max-device-width:480px) {
        .nav-item {
            margin-top:0;
        }

        .nav-link {
            margin-top:0;
            margin-botton:0;
        }

        .blur {
            min-height:0;
        }
    } 
`;