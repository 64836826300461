import React from 'react';
import { InfoConsumer } from '../Context';
import NewsParantSection from '../Sections/NewsParantSection';
import VideoSection from '../Sections/VideoSection';

const News = ({ lan }) => {
    return (
        <div className="container">
            <div className="row mt5 justify-content-center">
                <InfoConsumer>
                    {
                        data => {
                            const content = data.news_content.find(n => n.version === lan).content;
                            return (
                                <>
                                    <h2 className="mt-5 mb-5 text-center">{content.news_title}</h2>
                                    <NewsParantSection 
                                        news_list={content.news_list}  
                                        expand_btn_text={content.expand_btn_text}
                                        collapse_btn_text={content.collapse_btn_text}
                                    />
                                    <h2 className="mt-5 mb-5 text-center">{content.video_title}</h2>
                                    {
                                        content.videos.map(v => 
                                            <VideoSection title={v.title} embedId={v.embedId} isMultiple={true} />
                                        )
                                    }
                                </>
                            );
                        }
                    }
                </InfoConsumer>
            </div>
        </div>
    );
}

export default News;