import GetToken from "./GetToken";

const GetVideos = async (language) => {

    const result = await (await GetToken()).json();

    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/videos`,
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${result.access_token}`
            },
            body: JSON.stringify({
                active: true,
                language: language
            })
        }
    );

    return res.json();
}

export default GetVideos;