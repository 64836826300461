import React from 'react';

const AchSection = ({id, logo, title, text}) => {
    return (
        <div key={id} className="col-10 col-lg-3 max-auto mb-5">
            <div className="card" style={{ height: '16rem' }}>
                <div className="card-body">
                    <h3 className="card-title text-center">
                        <i className={logo}></i>
                    </h3>
                    <h3 className="card-title text-center text-uppercase">
                        {title}
                    </h3>
                    <h5 className="card-title text-center">
                        {text}
                    </h5>
                </div>
            </div>
        </div>
    );
}

export default AchSection;