import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ScoreSort from '../../Functions/ScoreSort';
import useScoreList from '../../Hooks/useScoreList';


const ScoreSection = ({ headers, score_list, annual_performs }) => {
    const { numShow, text, toggleScoreList } = useScoreList({ totalNum: score_list.length });
    const group_list = ScoreSort(score_list.slice(0, numShow));
    annual_performs = annual_performs.sort(({ year: a }, { year: b }) => b - a);

    const [resize, setResize] = useState(false);

    // const handleResize = () => {
    //     if (window.innerWidth > 760)
    //         setResize(false);
    //     else
    //         setResize(true);
    // };

    // useEffect(() => {
    //     handleResize();
    //     window.addEventListener("resize", handleResize);
    // }, []);

    return (
        <>
            <ScoreWrapper>

                <div className="card" >
                    <div className="row card-body">
                        {
                            !resize ?
                                <>
                                    <div className="col-sm-2" />
                                    <div className="col-sm-3">
                                        <h5 className="card-title text-center mt-2 mb-5">
                                            {headers[0]}
                                        </h5>
                                    </div>
                                    <div className="col-sm-2">
                                        <h5 className="card-title text-center mt-2 mb-5">
                                            {headers[1]}
                                        </h5>
                                    </div>
                                    <div className="col-sm-3">
                                        <h5 className="card-title text-center mt-2 mb-5">
                                            {headers[2]}
                                        </h5>
                                    </div>
                                    <div className="col-sm-2" />

                                </> :
                                ""
                        }
                        {
                            group_list.map(g =>
                                g.list.map(i =>
                                    <>
                                        {!resize ? <div className="col-sm-2" /> : ""}
                                        {resize ?
                                            <div className="col-3 champion-text">
                                            </div> : ""}
                                        <div className={!resize ? "col-sm-1" : "col-9 text-center"} >
                                            {g.order == 1 ?
                                                (<span className="fa-stack fa-2x">
                                                    <i className="fas fa-award fa-stack-2x champion-icon"></i>
                                                    <i className="fa-stack-1x champion-number">{g.order}</i>
                                                </span>) :
                                                <span className="fa-stack fa-lg">
                                                    <i className="fa-stack-1x">{g.order}</i>
                                                </span>
                                            }
                                        </div>
                                        {resize ?
                                            <div className="col-3 champion-text">
                                                <p className="card-text">
                                                    {headers[0]}
                                                </p>
                                            </div> : ""}
                                        <div className={`${!resize ? "col-sm-2" : "col-9"} 
                                                            ${g.order == 1 ? "champion-text" : "nornch-text"}`}>
                                            <p className={`card-text ${resize ? "text-center" : ""}`}>
                                                {i.name}
                                            </p>
                                        </div>
                                        {resize ? <div className="col-3 champion-text">
                                            <p className="card-text">
                                                {headers[1]}
                                            </p>
                                        </div> : ""}
                                        <div className={`${!resize ? "col-sm-2" : "col-9"} ${g.order == 1 ? "champion-text" : "nornch-text"}`}>
                                            <p className="card-text text-center">
                                                {i.atar}
                                            </p>
                                        </div>
                                        {!resize ? <div className="col-sm-1" /> : ""}
                                        {resize ? <div className="col-3 champion-text">
                                            <p className="card-text">
                                                {headers[2]}
                                            </p>
                                        </div> : ""}
                                        <div className={`${!resize ? "col-sm-2" : "col-9"} ${g.order == 1 ? "champion-text" : "nornch-text"}`}>
                                            <p className={`card-text ${resize ? "text-center" : ""}`}>
                                                {i.school}
                                            </p>
                                        </div>
                                        {!resize ? <div className="col-sm-2" /> : ""}

                                    </>
                                )
                            )
                        }
                        <button className='btn' onClick={toggleScoreList}>{text}</button>
                    </div>
                </div>
                <div className="col-10 col-lg-12 max-auto mb-5 mr-0"> <br /></div>

            </ScoreWrapper>
            {
                annual_performs.map(p =>
                    <AnnualPerform title={p.year} text={p.descripton} />
                )
            }
        </>
    );
}

export default ScoreSection;

const AnnualPerform = ({ title, text }) => {
    return (
        <div className="col-10 col-lg-3 max-auto mb-5">
            <div className="card" style={{ height: '16rem' }}>
                <div className="card-body">
                    <h3 className="card-title text-center text-uppercase">
                        {title}
                    </h3>
                    <h5 className="card-title text-center">
                        {text}
                    </h5>
                </div>
            </div>
        </div>
    );
}

const ScoreWrapper = styled.div`
    .nornch-text {
        padding-top:10px;
    }

    .champion-icon {
        text-align:left;
        color: var(--mainGold);
    }
    .champion-number {
        font-size:18px;
        font-weight:bold;
        margin-top:-11px;
        margin-left:-16px;
        color: var(--mainGold);
    }
    .champion-text {
        padding-top: 12px;
        font-weight: bold;
    }

    @media only screen and (max-device-width:480px) {
        .nornch-text, .champion-text {
            font-size:9px;
        }
        .champion-number {
            
            margin-top:-5px;
            margin-left:-6px;
        }
        .champion-icon {
            font-size:40px;
            margin-top:15px;
            margin-left:20px;
        }
        .fas-stack {
            height:1.5rem;
        }
        .fa-stack-1x {
            font-size: 12px;
        }
    }
`;