import { useState } from 'react';
import { maxBy, groupBy } from "lodash";

const useApplication = ({genders, error_book}) => {
    const initial_application = {
        applicant_name: "",
        applicant_chinese_name: "",
        mobile: "",
        email: "",
        social_media: "",
        gender: "none",
        dob: "",
        position: "",
        subject: "",
        specified_subject: "",
        education:[
            {
                id: 0,
                period: "",
                collage: "",
                qualification: "",
                major: ""
            }
        ],
        experience:[
            {
                id: 0,
                period: "",
                employer: "",
                position: ""
            }
        ],
        hear_from: "other",
        uploaded_file:{
            text: "",
            file: null
        }
    };

    const [application, setApplication] = useState(initial_application);
    const [validation, setValidation] = useState({validated: true, errors: []});

    const add_education = () => {
        const new_application = {...application};
        const new_id = maxBy(new_application.education, "id").id + 1;
        new_application.education = [...new_application.education, {
            id: new_id,
            period: "",
            collage: "",
            qualification: "",
            major: ""
        }];
        setApplication(new_application);
    };

    const remove_education = (id) => {
        const new_application = {...application};
        const eduction_to_remove = new_application.education.find(a => a.id === id);
        const index = new_application.education.indexOf(eduction_to_remove);
        new_application.education.splice(index, 1);
        setApplication(new_application);
    };

    const update_education = (id, property, value) => {
        const new_application = {...application};
        const eduction_to_update = new_application.education.find(a => a.id === id);
        eduction_to_update[property] = value;
        setApplication(new_application);
    };

    const add_experience = () => {
        const new_application = {...application};
        const new_id = maxBy(new_application.experience, "id").id + 1;
        new_application.experience = [...new_application.experience, {
            id: new_id,
            period: "",
            employer: "",
            position: ""
        }];
        setApplication(new_application);
    };

    const remove_experience = (id) => {
        const new_application = {...application};
        const experience_to_remove = new_application.experience.find(a => a.id === id);
        const index = new_application.experience.indexOf(experience_to_remove);
        new_application.experience.splice(index, 1);
        setApplication(new_application);
    };

    const update_experience = (id, property, value) => {
        const new_application = {...application};
        const experience_to_update = new_application.experience.find(a => a.id === id);
        experience_to_update[property] = value;
        setApplication(new_application);
    };

    const update = (property, value) => {
        const new_application = {...application};
        new_application[property] = value;
        setApplication(new_application);
    }

    const reset = () => {
        setApplication(initial_application);
    }

    const validate = () => {
        let errors = [];
        if(application.applicant_name === ""){
            errors = [...errors, {
                property: "applicant_name",
                message: error_book.applicant_name_missing
            }];
        }
        if(application.mobile === ""){
            errors = [...errors, {
                property: "mobile",
                message: error_book.mobile_missing
            }];
        }
        //Invalid Mobile validation to be implemented
        if(application.email === ""){
            errors = [...errors, {
                property: "email",
                message: error_book.email_missing
            }];
        }
        //Invalid Email validation to be implemented
        if(application.gender === genders.none){
            errors = [...errors, {
                property: "gender",
                message: error_book.gender_missing
            }];
        }
        if(application.dob === ""){
            errors = [...errors, {
                property: "dob",
                message: error_book.dob_missing
            }];
        }
        //Invalid DOB validation to be implemented
        if(application.position === ""){
            errors = [...errors, {
                property: "position",
                message: error_book.position_missing
            }];
        }
        if(application.subject === ""){
            errors = [...errors, {
                property: "subject",
                message: error_book.subject_missing
            }];
        }

        setValidation({validated: (errors.length === 0), errors: errors});
    };

    const clear_errors = () => {
        setValidation({validated: true, errors: []});
    }

    return {application, 
            validation,
            update, 
            add_education, 
            remove_education, 
            update_education,
            add_experience,
            remove_experience,
            update_experience,
            reset,
            validate,
            clear_errors,
            setValidation
        };
};

export default useApplication;