import {useState} from 'react';

const useAtar = (initialSelection, initialPrompt) => {
    const [selectedValue, setSelectedValue] = useState(0);
    const [selection, setSelection] = useState(initialSelection);
    const [contributions, setContributions] = useState([]);
    const [prompt, setPrompt] = useState(initialPrompt);
    const [aggregation, setAggregation] = useState(0);
    const [atar, setAtar] = useState(0);
    return {
        selectedValue,
        selection, 
        contributions,
        prompt,
        aggregation,
        atar,
        setSelectedValue,
        setSelection,
        setContributions,
        setPrompt,
        setAggregation,
        setAtar
    };
};

export default useAtar;