import React from 'react';
import { InfoConsumer } from '../Context';
import { Button, Modal } from 'react-bootstrap';
import ContactFormSection from '../Sections/ContactFormSection';


const Contact = ({ lan, isModal = true, isActive, handleClose }) => {
    return (
        <InfoConsumer>
            {
                data => {
                    console.log(lan);
                    console.log(JSON.stringify(data.contact_content));
                    const content = data.contact_content.find(c => c.version === lan)?.content;
                    const form_content = data.lectures_content.find(c => c.version === lan).content;
                    console.log(JSON.stringify(content));
                    if (isModal)
                        return (
                            <Modal show={isActive} onHide={handleClose}>
                                <Modal.Header colseButton>
                                    <Modal.Title>
                                        <h2>{content?.title}</h2>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <ContactFormSection
                                        form_labels={form_content.form_labels}
                                        error_book={form_content.error_book}
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        );
                    else
                        return (
                            <>
                                <h2 className="text-center">Contact Us</h2>
                                <br />
                                <ContactFormSection
                                    form_labels={form_content.form_labels}
                                    error_book={form_content.error_book}
                                    mobile={true}
                                />
                            </>
                        );
                }
            }
        </InfoConsumer>
    );
}

export default Contact;



