import { result } from "lodash";

const ValidateLectureForm = (students, result, setValidation, reset) => {
    if (result.error === "Bad Request") {
        setValidation({
            validated: false,
            errors: result.invalid_fields
        });
    }
    else if (result.error === "Conflict") {
        setValidation({
            validated: false,
            errors: result.keys.map(k => {
                return {
                    id: students.find(s => (k.name === "email" && s.email === k.value) || (k.name === "phone number" && s.phone_number === k.value)).id,
                    field_name: (k.name === "email" ? "email" : "phone_number"),
                    reason: "Already exists"
                };
            })
        });
    }
    else if(result.error === "Forbidden") {
        setValidation({
            validated: false,
            errors: [
                {
                    reason : result.message
                }
            ]
        });
    }
    else{
        setValidation({
            validated: true,
            errors: []
        });

        alert("Lecture applicarion form is submitted.");
        reset();
    }
        
};

const validateContact = (result, setValidation, reset) => {
    if (result.error === "Bad Request") {
        setValidation({
            validated: false,
            errors: result.invalid_fields
        });
    }
    else{
        setValidation({
            validated: true,
            errors: []
        });

        alert("Contact form is submitted.");
        reset();
    }
};

const validateOneOnOne = (result, setValidation, reset) => {
    if (result.error === "Bad Request") {
        setValidation({
            validated: false,
            errors: result.invalid_fields
        });
    }
    else{
        setValidation({
            validated: true,
            errors: []
        });

        alert("One on one learning application form is submitted.");
        reset();
    }
};

const ValidateJobForm = (result, setValidation, reset) => {
    if (result.error === "Bad Request") {
        setValidation({
            validated: false,
            errors: result.invalid_fields
        });
    }
    else{
        setValidation({
            validated: true,
            errors: []
        });

        alert("Job application form is submitted.");
        reset();
    }
};

export {ValidateLectureForm, validateContact, validateOneOnOne, ValidateJobForm};