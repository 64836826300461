import React from 'react';
import useNews from '../../Hooks/useNews';
import NewsSection from './NewsSection';

const NewsParantSection = ({news_list, expand_btn_text, collapse_btn_text}) => {
    const {numShown, expandVisable, collapseVisable, expand, collapse} = useNews({news_list: news_list})
    return (
        <>
            {
                news_list.slice(0, numShown).map(n => 
                    <NewsSection id={n.id} img={n.img} title={n.title} />    
                )
            }
            {
                expandVisable? 
                <button className='btn' onClick={expand}>{expand_btn_text}</button> :
                ""
            }
            {
                collapseVisable? 
                <button className='btn' onClick={collapse}>{collapse_btn_text}</button> :
                ""
            }
        </>
    );
};



export default NewsParantSection;
