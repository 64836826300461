import React from 'react';
import styled from 'styled-components';
import SubmitForm from '../../Functions/SubmitForm';
import useBioInfo from '../../Hooks/useBioInfo';
import { validateOneOnOne } from '../../Functions/Validate'

const OneOnOneSection = ({ form_labels, grades, campuses, subjects, error_book, resize }) => {

    const {
        students,
        validation,
        update,
        reset,
        setValidation
    } = useBioInfo({
        error_book: error_book
    });

    const handle_submit = async (e) => {
        e.preventDefault();
        let {
            first_name,
            last_name,
            school,
            grade,
            phone_number,
            email,
            subject,
            campus
        } = students[0];

        subject = subject_options.find(op => op.value === subject)?.label;
        campus = campus_options.find(cam => cam.value === campus)?.label;

        console.log(JSON.stringify({
            first_name,
            last_name,
            school,
            grade,
            phone_number,
            email,
            subject,
            campus
        }, null, 4));

        const result = await SubmitForm({
            first_name,
            last_name,
            school,
            grade,
            phone_number,
            email,
            subject,
            campus
        }, "/oneonone");

        validateOneOnOne(result, setValidation, reset);

        console.log(`validated: ${validation.validated}`)

        // if(validation.validated){
        //     //alert("done");
        //     reset();
        // }
        
    };

    const grade_options = Object.keys(grades).filter(key => key !== "none").map(key => {
        return {
            value: key,
            label: grades[key]
        };
    });

    let subject_options, other_subject = {
        id: "other",
        value: "other",
        label: subjects.other
    };
    [other_subject, ...subject_options] = Object.keys(subjects).map(key => {
        return {
            id: key,
            value: key,
            label: subjects[key]
        };
    });

    let campus_options, other_campus = {
        id: "other",
        value: "other",
        label: campuses.other
    };
    [other_campus, ...campus_options] = Object.keys(campuses).map(key => {
        return {
            id: key,
            value: key,
            label: campuses[key]
        };
    });

    return (
        <StyledForm onSubmit={handle_submit}>
            <div className="container">
                <div className="row mt5">
                    <TextboxCell
                        width={6}
                        label={`${form_labels.first_name}`}
                        warning={validation?.errors.find(err => err?.field_name == "first_name")?.reason??""}
                        id={"first_name"}
                        value={students[0].first_name}
                        onChange={e => update(0, "first_name", e.target.value)}
                        resize={resize}
                        required={true}
                    />
                    <TextboxCell
                        width={6}
                        label={`${form_labels.last_name}`}
                        warning={validation?.errors.find(err => err?.field_name == "last_name")?.reason??""}
                        id={"last_name"}
                        value={students[0].last_name}
                        onChange={e => update(0, "last_name", e.target.value)}
                        resize={resize}
                        required={true}
                    />
                    <TextboxCell
                        width={12}
                        label={`${form_labels.school}`}
                        id={"school"}
                        value={students[0].school}
                        onChange={e => update(0, "school", e.target.value)}
                        resize={resize}
                    />
                    <SelectCell
                        width={12}
                        label={`${form_labels.grade}`}
                        warning={validation?.errors.find(err => err?.field_name == "grade")?.reason??""}
                        id="grade"
                        value={students[0].grade}
                        onChange={e => update(0, "grade", e.target.value)}
                        options={grade_options}
                        resize={resize}
                        required={true}
                    />
                    <TextboxCell
                        width={12}
                        label={`${form_labels.phone}`}
                        warning={validation?.errors.find(err => err?.field_name == "phone_number")?.reason??""}
                        id={"phone_number"}
                        value={students[0].phone_number}
                        onChange={e => update(0, "phone_number", e.target.value)}
                        resize={resize}
                        required={true}
                    />
                    <TextboxCell
                        width={12}
                        label={`${form_labels.email}`}
                        warning={validation?.errors.find(err => err?.field_name == "email")?.reason??""}
                        id={"email"}
                        value={students[0].email}
                        onChange={e => update(0, "email", e.target.value)}
                        resize={resize}
                        required={true}
                    />
                    <RadioGroup
                        width={4}
                        label={form_labels.subject}
                        warning={validation?.errors.find(err => err?.field_name == "subject")?.reason??""}
                        options={subject_options}
                        compare={students[0].subject}
                        onChange={e => update(0, "subject", e.target.value)}
                        required={true}
                    />
                    <RadioGroup
                        width={4}
                        label={form_labels.campus}
                        warning={validation?.errors.find(err => err?.field_name == "campus")?.reason??""}
                        options={campus_options}
                        compare={students[0].campus}
                        onChange={e => update(0, "campus", e.target.value)}
                        required={true}
                    />
                    <SubmitCell width={2} text={form_labels.submit} />
                </div>
            </div>
        </StyledForm>
    );
};

export default OneOnOneSection;

const SubmitCell = ({ width, text }) => {
    return (
        <>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-3`}></div>
            <div className={`col-10 col-lg-${width} max-auto mb-3`}>
                <input type="submit" className="btn btn-success" value={text} />
            </div>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-3`}></div>
        </>
    );
};

const RadioCell = ({ width, label, id, value, compare, onChange }) => {
    console.log(`value: ${value}, compare: ${compare}, value===compare: ${value === compare}`);
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            <input
                type="radio"
                className="btn-check"
                id={id}
                name={id}
                value={value}
                checked={value === compare}
                onChange={onChange}
            />
            <label className="btn btn-outline-success" htmlFor={id}>{label}</label>
        </div>
    );
};

const RadioGroup = ({ width, label, options, compare, onChange, required, warning="" }) => {
    return (
        <>
            <StyledLabel>{label} {required ? "*" : ""} <span style={{color: "red"}}>{warning}</span></StyledLabel>
            <div className="btn-group">
                <div className="container">
                    <div className="row mt5">
                        {
                            options.map(option =>
                                <RadioCell
                                    width={width}
                                    label={option.label}
                                    id={option.id}
                                    value={option.value}
                                    compare={compare}
                                    onChange={onChange}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

const TextboxCell = ({ width, label, id, value, onChange, required, resize, warning="" }) => {
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            {resize ? "" : <StyledLabel htmlFor={id}>{label} {required ? "*" : ""} <span style={{color: "red"}}>{warning}</span></StyledLabel>}
            <input
                id={id}
                name={id}
                className="form-control"
                value={value}
                placeholder={label}
                type="text"
                onChange={onChange} />
        </div>
    );
};

const SelectCell = ({ width, label, id, value, onChange, options, required, resize, warning="" }) => {
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            {resize ? "" : <StyledLabel htmlFor={id}>{label} {required ? "*" : ""} <span style={{color: "red"}}>{warning}</span></StyledLabel>}
            <select
                id={id}
                name={id}
                className={`form-select ${value === "none" ? "select-placeholder" : ""}`}
                value={value}
                onChange={onChange}
            >
                <option value="" hidden>{label}</option>
                {
                    options.map(o =>
                        <option value={o.value}>{o.label}</option>
                    )
                }
            </select>
        </div>
    );
};

const StyledForm = styled.form`
    input, select {
        width: 100%;
    }
`;

const StyledLabel = styled.label`
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight:600;
`;