import React, { useState, useEffect, useRef }  from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const PhotoGallerySection = ({ title, photos }) => {
    return (
            <div className="col-10 col-lg-12 max-auto mb-5 mr-0">
                <div className="card" >
                    <div className="row card-body">
                        <div className="col-sm-12">
                            <h3 className="card-title text-center mt-2 mb-5">
                                {title}
                            </h3>
                            <Carousel photos={photos} />
                        </div>
                    </div>
                </div>
            </div>
    );
}

const Carousel  = ({photos}) => {
    const [percent, setPercent] = useState(0.6);
    const section = useRef(null);
    const [padding, setPadding] = useState(0);

    const handleResize = () => {
        if(window.innerWidth > 760)
            setPercent(0.6);
        else if(window.innerWidth <= 760 && window.innerWidth > 480)
            setPercent(0.4);
        else
            setPercent(0.2);
    };

    const items = photos.map(ph => 
        <div className="item" data-value={`${ph.id}`}>
            <img src={ph.img} alt={`Photo ${ph.id}`} />
        </div>
    );
   
    const syncState = () => {
        handleResize();
        window.addEventListener("resize", handleResize);

        const { current } = section;
        if (current) {
            setPadding(current.offsetWidth * percent);
        }
    };
   
    useEffect(syncState, []);
   
    return (
        <div ref={section}>
            <AliceCarousel
                infinite
                mouseTracking
                items={items} 
                paddingRight={padding} 
                onResized={syncState} 
            />
        </div>
    );
}

export default PhotoGallerySection;