import { useState } from 'react';
import { maxBy, groupBy } from "lodash";

const useBioInfo = ({ initial_id = 0, error_book }) => {
    const initial_student = {
        id: initial_id,
        first_name: "",
        last_name: "",
        chinese_name: "",
        gender: "none",
        phone_number: "",
        wechat: "",
        email: "",
        grade: "none",
        campus: "none",
        school: "",
        interested_subject: "other"
    };

    const [students, setStudents] = useState([initial_student]);
    const [validation, setValidation] = useState({validated: true, errors: []});

    const add = () => {
        const new_id = maxBy(students, "id").id + 1;
        const new_students = [...students, {
            id: new_id,
            first_name: "",
            last_name: "",
            chinese_name: "",
            gender: "none",
            phone_number: "",
            wechat: "",
            email: "",
            grade: "none",
            campus: "none",
            school: "",
            interested_subject: "other"
        }];
        setStudents(new_students);
    };

    const remove = (id) => {
        const new_students = [...students];
        const student_to_remove =new_students.find(s => s.id === id);
        const index = new_students.indexOf(student_to_remove);
        new_students.splice(index, 1);
        setStudents(new_students);
    };

    const update = (id, property, value) => {
        const new_students = [...students];
        const student_to_update = new_students.find(s => s.id === id);
        student_to_update[property] = value;
        setStudents(new_students);
    };

    const reset = () => {
        setStudents([initial_student]);
    };

    const validate = () => {
        let errors = [];
        students.forEach(s => {
            if(s.first_name === "")
                errors = [...errors, {
                    id: s.id,
                    property: "first_name",
                    message: error_book.first_name_missing
                }];
            if(s.last_name === "")
                errors = [...errors, {
                    id: s.id,
                    property: "last_name",
                    message: error_book.last_name_missing
                }];
            if(s.gender === "none")
                errors = [...errors, {
                    id: s.id,
                    property: "gender",
                    message: error_book.gender_missing
                }];
            if(s.phone_number === "")
                errors = [...errors, {
                    id: s.id,
                    property: "phone_number",
                    message: error_book.phone_number_missing
                }];
            // else if(invalid_phone)
            //     errors = [...errors, {
            //         id: s.id,
            //         property: "phone_number",
            //         message: error_book.phone_number_invalid
            //     }];
            if(s.email === "")
                errors = [...errors, {
                    id: s.id,
                    property: "email",
                    message: error_book.email_missing
                }];
            // else if(invalid_email)
            //     errors = [...errors, {
            //         id: s.id,
            //         property: "email",
            //         message: error_book.email_invalid
            //     }];
            if(s.grade === "none")
                errors = [...errors, {
                    id: s.id,
                    property: "grade",
                    message: error_book.grade_missing
                }];
            if(s.campus === "none")
                errors = [...errors, {
                    id: s.id,
                    property: "campus",
                    message: error_book.campus_missing
                }];
            if(s.school === "")
                errors = [...errors, {
                    id: s.id,
                    property: "school",
                    message: error_book.school_missing
                }];
        });
        if(errors.length === 0){
            const byPhoneNumber = groupBy(students, student => student.phone_number);
            const phone_number_list = Object.keys(byPhoneNumber).map(key => {return {
                                phone_number: `${key}`,
                                students: byPhoneNumber[key]
                            }}).filter(item => item.students.length > 1);
            errors = [...errors, ...phone_number_list.map(item => {return {message:error_book.phone_number_duplicate.replace("<>", item.phone_number)};})];
            phone_number_list.forEach(item => {
                errors = [...errors, ...item.students.map(s => {
                    return {
                        id: s.id,
                        property: s.phone_number,
                        message: "*"
                    };
                })]
            });

            const byEmail = groupBy(students, student => student.email);
            const email_list = Object.keys(byEmail).map(key => {return {
                                email: `${key}`,
                                students: byPhoneNumber[key]
                            }}).filter(item => item.students.length > 1);
            errors = [...errors, ...email_list.map(item => {return {message:error_book.email_duplicate.replace("<>", item.email)};})];
            email_list.forEach(item => {
                errors = [...errors, ...item.students.map(s => {
                    return {
                        id: s.id,
                        property: s.email,
                        message: "*"
                    };
                })]
            });
        } 

        setValidation({validated : (errors.length === 0), errors: errors});
    };

    const clear_errors = () => {
        setValidation({validated: true, errors: []});
    }

    return {students, validation, add, remove, update, reset, setValidation, validate, clear_errors};
};

export default useBioInfo;