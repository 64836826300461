import GetToken from "./GetToken";

const SubmitForm = async(formObject, path = "") => {

    const result = await(await GetToken()).json();

    const res = await fetch(
        `${process.env.REACT_APP_API_URL}${path}`,
        {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${result.access_token}`
            },
            body: JSON.stringify(formObject)
        }
    );

    if (res.status === 201)
        return { id: (await res.json())._id };
    else
        return res.json();
}

export default SubmitForm;