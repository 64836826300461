import styled from 'styled-components';
import { Button, Overlay } from 'react-bootstrap';
import { useState, useRef } from 'react';
import { InfoConsumer } from '../Context';

const Bar = ({ campuses }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    return (
        <div className="position-fixed bottom-0 end-0">
            <Button variant="success" style={{ margin: "10px 10px 10px 10px" }} ref={target} onClick={() => setShow(!show)}>
                <h3><i className="fas fa-comments"></i></h3>
            </Button>
            <Overlay target={target.current} show={show} placement="top">
                {
                    ({ placement, arrowProps, show: _show, popper, ...props }) => (
                        <StyledOverlay
                            {...props}
                        >
                            <div className="container">
                                <div className="row mt5 d-flex justify-content-center">
                                    {
                                        campuses.map(c =>
                                            <>
                                                <div className="col-10 col-lg-5 max-auto">
                                                    <img src={c.QR} alt={c.name} className="col-sm-12 text-center" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                                </div>
                                                <div className="col-10 col-lg-7 max-auto">
                                                    <h5 className="text-center">
                                                        {c.name}
                                                    </h5>
                                                    <h5 className="text-center">
                                                        <i className="fas fa-phone"></i>
                                                    </h5>
                                                    <h5 className="text-center">
                                                        <a className="text-decoration-none" href="#">
                                                            {c.phone}
                                                        </a>
                                                    </h5>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </StyledOverlay>
                    )
                }
            </Overlay>
        </div>
    );
};

const InfoBar = ({ lan }) => {
    return (
        <InfoConsumer>
            {
                data => {
                    const campuses = data.footer_content.find(f => f.version === lan).content.campuses;
                    return (
                        <Bar campuses={campuses} />
                    );
                }
            }
        </InfoConsumer>
    );
};

export default InfoBar;

const StyledOverlay = styled.div`

    position: absolute;
    background-color: var(--mainDeepGreen);
    padding: 2px 10px;
    margin: 10px 10px 10px 10px;
    color: var(--mainWhite);
    border-radius: 3px;
    width:300px;
    a {
        color: var(--mainLightGrey);
    }

    @media only screen and (max-device-width:480px) {
        width:150px;
        h5, p {
            font-size:0.8rem;
        }
    }
`;