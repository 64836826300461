import React, { useState, useEffect } from 'react';
import { InfoConsumer } from '../Context';
import EspLectureSection from '../Sections/EspLectureSection';
import { Tab, Tabs } from 'react-bootstrap';
import CeeLectureSection from '../Sections/CeeLectureSection';
import AeasLectureSection from '../Sections/AeaslectureSection';
import CasperLectureSection from '../Sections/CasperSection';
import styled from 'styled-components';
import OneOnOneSection from '../Sections/OneOnOneSection';
import GetCurrentSession from '../../Functions/GetCurrentSession';
import useSurvey from '../../Hooks/useSurvey';

const Lectures = ({ lan }) => {
    const [tabkey, setTabkey] = useState('One');

    const [resize, setResize] = useState(false);

    const { espSession, ceeSession, setEspSession, setCeeSession } = useSurvey({});

    const handleResize = () => {
        if (window.innerWidth > 760)
            setResize(false);
        else
            setResize(true);
    };

    useEffect(() => {
        const initailSessions = async () => {
            setEspSession(await GetCurrentSession("esp"));
            setCeeSession(await GetCurrentSession("cee"));
        };

        initailSessions().catch(console.err);
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);

    return (
        <div className="container">
            <div className="row mt5">
                <InfoConsumer>
                    {
                        data => {
                            const content = data.lectures_content.find(l => l.version === lan).content;
                            const tab_title = content.tab_title;
                            const form_labels = content.form_labels;
                            const genders = content.genders;
                            const grades = content.grades;
                            const campuses = content.campuses;
                            const subjects = content.subjects;
                            const esp_lecture_time = espSession ? espSession.location_date : content.esp_lecture_time;
                            const ce_lecture_time = ceeSession ? ceeSession.location_date :content.ce_lecture_time;
                            const hear_from = content.hear_from;
                            const favorite_uni = content.favorite_uni;
                            const favorite_major = content.favorite_major;
                            const error_book = content.error_book;
                            return (
                                <StyledFormContainer>
                                    <h2 className="mt-5 mb-5 text-center">{content.title}</h2>
                                    <br />
                                    <StyledTabContainer resize={resize}>
                                        <Tabs activeKey={tabkey} onSelect={e => setTabkey(e)}>
                                            <Tab eventKey="One" title={tab_title.tab1}>
                                                <EspLectureSection
                                                    form_labels={form_labels}
                                                    genders={genders}
                                                    grades={grades}
                                                    campuses={campuses}
                                                    lecture_time={esp_lecture_time}
                                                    hear_from={hear_from}
                                                    error_book={error_book}
                                                    resize={resize}
                                                />
                                            </Tab>
                                            <Tab eventKey="Two" title={tab_title.tab2}>
                                                <CeeLectureSection
                                                    form_labels={form_labels}
                                                    genders={genders}
                                                    grades={grades}
                                                    campuses={campuses}
                                                    lecture_time={ce_lecture_time}
                                                    hear_from={hear_from}
                                                    favorite_uni={favorite_uni}
                                                    favorite_major={favorite_major}
                                                    error_book={error_book}
                                                    resize={resize}
                                                />
                                            </Tab>
                                            <Tab eventKey="Three" title={tab_title.tab3}>
                                                <AeasLectureSection
                                                    form_labels={form_labels}
                                                    genders={genders}
                                                    grades={grades}
                                                    campuses={campuses}
                                                    hear_from={hear_from}
                                                    error_book={error_book}
                                                    resize={resize}
                                                />
                                            </Tab>
                                            <Tab eventKey="Four" title={tab_title.tab4}>
                                                <CasperLectureSection
                                                    form_labels={form_labels}
                                                    genders={genders}
                                                    grades={grades}
                                                    campuses={campuses}
                                                    hear_from={hear_from}
                                                    favorite_uni={favorite_uni}
                                                    error_book={error_book}
                                                    resize={resize}
                                                />
                                            </Tab>
                                        </Tabs>
                                    </StyledTabContainer>
                                    <p>
                                        <br />
                                    </p>
                                    <h2 className="mt5 mb-5 text-center">{content.on_on_one_title}</h2>
                                    <div className="card">
                                        <br />
                                        <OneOnOneSection
                                            form_labels={form_labels}
                                            grades={grades}
                                            campuses={campuses}
                                            subjects={subjects}
                                            error_book={error_book}
                                            resize={resize}
                                        />
                                    </div>
                                    <p>
                                        <br />
                                    </p>
                                </StyledFormContainer>
                            );
                        }
                    }
                </InfoConsumer>
            </div>
        </div>
    );
}

export default Lectures;

const StyledTabContainer = styled.div`
    .tab-content {
        border-left: 2px solid #ddd;
        border-right: 2px solid #ddd;
        border-bottom: 2px solid #ddd;
        padding: 10px;
    }

    .nav-tabs {
        margin-bottom: 0;
    }

    .nav-item {
        ${(props) => props.resize ? "margin-bottom:10px;" : ""}
        margin-right:10px;
        ${(props) => props.resize ? "width:100%;" : "width:250px;"}
        
    }

    .nav-item button {
        width:100%;
        height:100%
    }

    .nav-link  {
        background-color: var(--mainGreen);
        color: var(--mainDark)
    }

    .nav-link.active {
        font-weight:bold
    }
`;

const StyledFormContainer = styled.div`
    .btn {
        width:300px;
    }

    .select-placeholder {
        color: var(--mainLightGrey);
        option {
            color:black;
        }
    }

    @media only screen and (max-device-width:480px) {
        .btn {
            width:180px;
            font-size:12px;
        }
    
        .btn-group .btn {
            width:180px;
            font-size:12px;
            margin-left:-20px;
        }

        .form-select, .form-control {
            font-size:12px;
            width:240px;
        }

        .tab-content{
            .form-select, .form-control {
                margin-left:-40px;
            }
        }

        #specify{
            margin-left:-20px;
        }

        label{
            font-size: 12px;
        }

        h2 {
            font-size: 18px;
        }
    }
`;