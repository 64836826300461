import React from 'react';
import styled from 'styled-components';
import useBioInfo from '../../Hooks/useBioInfo';
import { validateContact } from "../../Functions/Validate";
import SubmitForm from '../../Functions/SubmitForm';

const ContactFormSection = ({ form_labels, error_book, mobile = false }) => {
    const { students, update, reset, validation, setValidation } = useBioInfo({
        error_book: error_book
    });

    const handle_submit = async(e) => {
        e.preventDefault();
        const {
            first_name,
            last_name,
            phone_number,
            email,
            wechat
        } = students[0];

        console.log(JSON.stringify({
            first_name,
            last_name,
            phone_number,
            email,
            wechat
        }, null, 4));

        const result = await SubmitForm({
            first_name,
            last_name,
            phone_number,
            email,
            wechat
        }, "/contact");

        validateContact(result, setValidation, reset);

        // if (validation.validated){
        //     //alert("done");
        //     reset();
        // }
            
    };

    return (
        <StyledForm onSubmit={handle_submit}>
            <div className="container">
                <div className="row mt5">
                    <TextCell
                        width={6}
                        id="first_name"
                        label={form_labels.first_name}
                        warning={validation?.errors.find(err => err?.field_name == "first_name")?.reason??""}
                        value={students[0].first_name}
                        onChange={e => update(0, "first_name", e.target.value)}
                        required={true}
                        mobile={mobile}
                    />
                    <TextCell
                        width={6}
                        id="last_name"
                        label={form_labels.last_name}
                        warning={validation?.errors.find(err => err?.field_name == "last_name")?.reason??""}
                        value={students[0].last_name}
                        onChange={e => update(0, "last_name", e.target.value)}
                        required={true}
                        mobile={mobile}
                    />
                    <TextCell
                        width={12}
                        id="phone_number"
                        label={form_labels.phone}
                        warning={validation?.errors.find(err => err?.field_name == "phone_number")?.reason??""}
                        value={students[0].phone_number}
                        onChange={e => update(0, "phone_number", e.target.value)}
                        required={true}
                        mobile={mobile}
                    />
                    <TextCell
                        width={6}
                        id="email"
                        label={form_labels.email}
                        warning={validation?.errors.find(err => err?.field_name == "email")?.reason??""}
                        value={students[0].email}
                        onChange={e => update(0, "email", e.target.value)}
                        required={true}
                        mobile={mobile}
                    />
                    <TextCell
                        width={6}
                        id="wechat"
                        label={form_labels.wechat}
                        value={students[0].wechat}
                        onChange={e => update(0, "wechat", e.target.value)}
                        mobile={mobile}
                    />
                    <SubmitCell
                        width={4}
                        text={form_labels.submit}
                    />
                </div>
            </div>
        </StyledForm>
    );
};

export default ContactFormSection;

const TextCell = ({ width, id, label, value, onChange, required, mobile, warning = "" }) => {
    return (
        <div className={`form-group col-10 col-lg-${width} max-auto mb-3`}>
            {mobile ? "" : <StyledLabel htmlFor={id}>{label} {required ? "*" : ""} <span style={{ color: "red" }}>{warning}</span></StyledLabel>}
            <input
                id={id}
                name={id}
                className="form-control"
                value={value}
                placeholder={label}
                type="text"
                onChange={onChange} />
        </div>
    );
};

const SubmitCell = ({ width, text }) => {
    return (
        <>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-3`}></div>
            <div className={`col-10 col-lg-${width} max-auto mb-3`}>
                <input type="submit" className="btn btn-success" value={text} />
            </div>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-3`}></div>
        </>
    );
};

const StyledForm = styled.form`
    input, select {
        width: 100%;
    }

    @media only screen and (max-device-width:480px) {
        .btn {
            width:260px;
            font-size:12px;
        }

        .form-control {
            font-size:12px;
            width:260px;
        }
    }
`;

const StyledLabel = styled.label`
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight:600;
`;