import { groupBy } from "lodash";

const ScoreSort = (list)=>{

    const byScore = groupBy(list, student =>{
        return student.atar;
    });

    var groupList = [];
    
    for(var key in byScore){
        groupList.push({atar:`${key}`, list:byScore[key]});
    }

    groupList = groupList.sort(({atar:a},{atar:b}) => b-a);

    var order = 0;
    var count = 0
    groupList.forEach(group => {
        order = count + 1;
        count += group.list.length;
        group.order = order;
        console.log(group.order);
    });
    return groupList;
}

export default ScoreSort;