import React from 'react';
import styled from 'styled-components';

const BioInfoSection = ({
    form_labels,
    genders,
    grades,
    campuses,
    error_book,
    students,
    add,
    remove,
    update,
    apply_private_school = false,
    resize,
    validation = {validated: true, errors: []}
}) => {

    const gender_options = Object.keys(genders).filter(key=>key!=="none").map(key => {
        return {
            value: key,
            label: genders[key]
        };
    });

    const grade_options = Object.keys(grades).filter(key=>key!=="none").map(key => {
        return {
            value: key,
            label: grades[key]
        };
    });

    const campus_options = Object.keys(campuses).filter(key=>key!=="none").map(key => {
        return {
            value: key,
            label: campuses[key]
        };
    });
    return (
        <>
            <div className="col-10 col-lg-12 max-auto mb-3 mr-0">
                <div className="container ">
                    <div className="row mt5">
                        {students.map(s =>
                            <>
                                <TextCell
                                    width={4}
                                    id={`first_name_${s.id}`}
                                    label={form_labels.first_name}
                                    warning={validation?.errors.find(err => err?.id == s.id && err?.field_name == "first_name")?.reason??""}
                                    value={s.first_name}
                                    onChange={e => update(s.id, "first_name", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextCell
                                    width={4}
                                    id={`last_name_${s.id}`}
                                    label={form_labels.last_name}
                                    warning={validation?.errors.find(err => err?.id == s.id && err?.field_name == "last_name")?.reason??""}
                                    value={s.last_name}
                                    onChange={e => update(s.id, "last_name", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextCell
                                    width={4}
                                    id={`chinese_name_${s.id}`}
                                    label={form_labels.ch_name}
                                    value={s.chinese_name}
                                    onChange={e => update(s.id, "chinese_name", e.target.value)}
                                    resize={resize}
                                />
                                <SelectCell
                                    width={3}
                                    id={`gender${s.id}`}
                                    label={form_labels.gender}
                                    warning={validation?.errors.find(err => err?.id == s.id && err?.field_name == "gender")?.reason??""}
                                    value={s.gender}
                                    options={gender_options}
                                    onChange={e => update(s.id, "gender", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextCell
                                    width={3}
                                    id={`phone_number${s.id}`}
                                    label={form_labels.phone}
                                    warning={validation?.errors.find(err => err?.id == s.id && err?.field_name == "phone_number")?.reason??""}
                                    value={s.phone_number}
                                    onChange={e => update(s.id, "phone_number", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextCell
                                    width={3}
                                    id={`email${s.id}`}
                                    label={form_labels.email}
                                    warning={validation?.errors.find(err => err?.id == s.id && err?.field_name == "email")?.reason??""}
                                    value={s.email}
                                    onChange={e => update(s.id, "email", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextCell
                                    width={3}
                                    id={`wechat${s.id}`}
                                    label={form_labels.wechat}
                                    value={s.wechat}
                                    onChange={e => update(s.id, "wechat", e.target.value)}
                                    resize={resize}
                                />
                                <SelectCell
                                    width={4}
                                    id={`grade${s.id}`}
                                    label={form_labels.grade}
                                    warning={validation?.errors.find(err => err?.id == s.id && err?.field_name == "grade")?.reason??""}
                                    value={s.grade}
                                    options={grade_options}
                                    onChange={e => update(s.id, "grade", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <SelectCell
                                    width={8}
                                    id={`campus${s.id}`}
                                    label={form_labels.campus}
                                    warning={validation?.errors.find(err => err?.id == s.id && err?.field_name == "campus")?.reason??""}
                                    value={s.campus}
                                    options={campus_options}
                                    onChange={e => update(s.id, "campus", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextCell
                                    width={12}
                                    id={`school${s.id}`}
                                    label={form_labels.school}
                                    value={s.school}
                                    onChange={e => update(s.id, "school", e.target.value)}
                                    resize={resize}
                                />
                                {
                                    apply_private_school ?
                                        <>
                                            <TextCell
                                                width={12}
                                                id={`intended_private_school${s.id}`}
                                                label={form_labels.private_school}
                                                value={s.intended_private_school}
                                                onChange={e => update(s.id, "intended_private_school", e.target.value)}
                                                required={true}
                                                resize={resize}
                                            />
                                        </> :
                                        ""
                                }
                                {
                                    (students.length > 1) ?
                                        <>
                                            <ButtonCell
                                                width={2}
                                                text={form_labels.remove}
                                                onClick={() => remove(s.id)}
                                                feature="btn-danger"
                                            />
                                        </> :
                                        ""
                                }
                                <br />
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ButtonCell
                width={2}
                text={form_labels.add_student}
                onClick={() => add()}
                feature="btn-success"
            />

        </>
    );
};

export default BioInfoSection;

const ButtonCell = ({ width, text, onClick, feature }) => {
    return (
        <>
            <div className={`col-10 col-lg-${width} max-auto mb-5 mr-0`}>
                <button type='button' className={`btn ${feature}`} onClick={onClick}>{text}</button>
            </div>
            <div className={`col-10 col-lg-${12-width} max-auto mb-5 mr-0`}></div>
        </>
    );
};

const TextCell = ({ width, id, label, value, onChange, required, resize, warning="" }) => {
    return (
        <div className={`form-group col-10 col-lg-${width} max-auto mb-3`}>
            {resize?"":<StyledLabel htmlFor={id}>{label} {required ? "*" : ""} <span style={{color: "red"}}>{warning}</span></StyledLabel>}
            <input
                id={id}
                name={id}
                className="form-control"
                value={value}
                placeholder={label}
                type="text"
                onChange={onChange} />
        </div>
    );
};

const SelectCell = ({ width, id, label, value, options, onChange, required, resize, warning="" }) => {
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            <div className="form-group">
                {resize?"":<StyledLabel htmlFor={id}>{label} {required ? "*" : ""} <span style={{color: "red"}}>{warning}</span></StyledLabel>}
                <select
                    id={id}
                    name={id}
                    className={`form-select ${value==="none"?"select-placeholder":""}`}
                    value={value}
                    onChange={onChange}
                >
                    <option value="" hidden>{label}</option>
                    {options.map(o =>
                        <option value={o.value}>{o.label}</option>
                    )}
                </select>
            </div>
        </div>
    );
};

const StyledLabel = styled.label`
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight:600;
`;