import React from 'react';
import styled from 'styled-components';

const CoursePrimarySection = ({ title, text, labels }) => {
    return (
        <>
            <div className="col-10 col-lg-12 max-auto mb-5 mr-0">
                <div className="card" >
                    <div className="row card-body">
                        <div className="col-sm-12">
                            <h3 className="card-title mt-2 mb-5 text-center">
                                {title}
                            </h3>
                            <p className="card-text" style={{"white-space": "pre-wrap"}}>
                                {text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-10 col-lg-1 max-auto mb-5 mr-0"></div>
            <div className="col-10 col-lg-10 max-auto mb-5 mr-0">
                <div className="container ">
                    <div className="row mt5">
                        {
                            labels.map(l =>
                                <Label title={l.title} text={l.text} />
                            )

                        }
                    </div>
                </div>
            </div>
            <div className="col-10 col-lg-1 max-auto mb-5 mr-0"></div>
        </>
    );
}

export default CoursePrimarySection;

const Label = ({ title, text }) => {
    return (
        <div className="col-10 col-lg-4 max-auto mb-5">
            <div className="card" style={{ height: '12rem' }}>
                <div className="card-body">
                    <StyledLabel>
                        <h3 className="card-title text-center text-uppercase">
                            {title}
                        </h3>
                        <h5 className="card-title text-center">
                            {text}
                        </h5>
                    </StyledLabel>
                </div>
            </div>
        </div>
    );
}

const StyledLabel = styled.div`
    @media only screen and (max-device-width:480px){
        h5 {font-size: 16px;}
    }
`;