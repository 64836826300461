import {useState} from 'react';

const useLanguage = (initialLanguage = "ch") => {   
    const [language, setlanguage] = useState(initialLanguage);
    const toggleLanguage = () => {
        if(language == "en")
            setlanguage("ch");
        else
            setlanguage("en");
    };
    return {language, setlanguage, toggleLanguage};
};

export default useLanguage;