import { useState, useEffect } from "react";


const VideoSection = ({ title, embedId, isMultiple}) => {
    const [resize, setResize] = useState(false);

    const handleResize = () => {
        if(window.innerWidth > 760)
            setResize(false);
        else
            setResize(true);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);

    return (
        
            <div className={`col-10 col-lg-${isMultiple?6:12} max-auto mb-5 mr-0`}>
                <div className="card" >
                    <div className="row card-body">
                            <iframe
                                width="853"
                                height={`${resize?"240":"480"}`}
                                src={`https://www.youtube.com/embed/${embedId}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title={title}
                            />
                    </div>
                </div>
            </div>
        
    );
}

export default VideoSection;
