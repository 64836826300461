import { useState } from 'react';

const useSurvey = ({ defaultLectureTime = "",
        defaultHearFrom = "",
        defaultFavoriteUni = "",
        defaultFavoriteMajor = ""}) => {

        const [espSession, setEspSession] = useState(undefined);
        const [ceeSession, setCeeSession] = useState(undefined);
        const [lectureTime, setLectureTime] = useState(defaultLectureTime);
        const [hearFrom, setHearFrom] = useState(defaultHearFrom);
        const [specify, setSpecify] = useState("");
        const [favoriteUni, setFavoriteUni] = useState(defaultFavoriteUni);
        const [favoriteMajor, setFavoriteMajor] = useState(defaultFavoriteMajor);

        return {
                espSession,
                ceeSession,
                lectureTime,
                hearFrom,
                specify,
                favoriteUni,
                favoriteMajor,
                setEspSession,
                setCeeSession,
                setLectureTime,
                setHearFrom,
                setSpecify,
                setFavoriteUni,
                setFavoriteMajor
        };
};

export default useSurvey;