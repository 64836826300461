import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InfoConsumer } from '../Context';
import subjects from '../../Atar/subjects.json';
import scaling from '../../Atar/scaling.json';
import atar_agg from '../../Atar/atar_aggr.json';
import useAtar from '../../Hooks/useAtar';
import { useRef } from 'react';


function AtarCalculator({ lan }) {
    const english_subjects = subjects.filter(s => s.english_subject);
    const {
        selectedValue,
        selection,
        contributions,
        prompt,
        aggregation,
        atar,
        setSelectedValue,
        setSelection,
        setContributions,
        setPrompt,
        setAggregation,
        setAtar
    } = useAtar(english_subjects, "");

    const handle_submit = (e) => {
        e.preventDefault();
    }

    const [resize, setResize] = useState(false);

    const handleResize = () => {
        if (window.innerWidth > 760)
            setResize(false);
        else
            setResize(true);
    };

    const prev_option_ref = useRef(0);
    const prev_score_ref = useRef(0);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        reset_selections();
        const {aggregation_result, atar_result} = atar_calc();
        setAggregation(aggregation_result);
        setAtar(atar_result);
    }, [contributions]);

    const reset_selections = () => {
        if (!contributions.some(c => subjects.find(s => s.code === c.code).english_subject)) {
            setSelection(english_subjects.filter(s => !contributions.some(c => c.code === s.code)));
            setPrompt("Please select an English subject.");
        }
        else {
            setSelection(subjects.filter(s => !contributions.some(c => c.code === s.code)));
            setPrompt(`Please select ${contributions.filter(c => c.major).length < 4 ? "a major" : "an additional"} subject.`);
        }
    };

    const subject_selected = (code) => {
        const prev_option = prev_option_ref.current;
        //Remove previous Contribution
        setContributions([contributions.filter(c => c.code !== prev_option)]);
        //Set Selected Value
        setSelectedValue(code);
        //Add Selected Contribution
        if (contributions.length < 6) {
            const clone = [...contributions, {
                code: code,
                title: subjects.find(s => s.code === code).title,
                raw: 0,
                pf: interpolatingPolynomial(get_scaling_matrix(code)),
                lf: interpolatingLinear(code),
                major: contributions.filter(c => c.major).length < 4
            }];
            setContributions(clone);
        }
        else {
            setContributions([...contributions]);
            alert("You cannot select more subjects.");
        }

        prev_option_ref.current = code;
    };

    const value_changed = (code, value) => {
        const prev_score = prev_score_ref.current;
        //Clone Contributions
        var clone = [...contributions];
        const reg = /^[0-9\b]+$/;
        if (value !== "" && !reg.test(value)) {
            alert("Raw scores must be numbers.");
            value = prev_score;
        }
        else if (Number(value) > 50) {
            alert("Raw scores are only between 0 and 50.");
            //Revert to previous value
            value = prev_score;
        }

        clone.find(c => c.code === code).raw = value;
        //Update Contributions
        setContributions(clone);

        prev_score_ref.current = value;
    };

    const remove_button_click = (code) => {
        //Remove Contribution with given code
        setContributions(contributions.filter(c => c.code !== code));
    };

    const clear = () => {
        setContributions([]);
    };

    const atar_calc = () => {
        let aggregation = 0;
        for (let contribution of contributions) {
            aggregation += (contribution.major ? 1 : 0.1) * (contribution.raw >= 20 ? contribution.pf(contribution.raw).toFixed(2) : contribution.lf(contribution.raw).toFixed(2));
        }
        return {
            aggregation_result: aggregation,
            atar_result: binary_search(atar_agg, aggregation, Math.min(...(atar_agg.map(a => a.id))), Math.max(...(atar_agg.map(a => a.id))))
        };
    }

    const binary_search = (arr, x, start, end) => {
        if (start > end)
            return undefined;
        let mid = Math.floor((start + end) / 2);
        if (arr.find(a => a.id === mid).aggr_lower <= x && (!arr.find(a => a.id === mid + 1) || arr.find(a => a.id === mid + 1).aggr_lower > x))
            return arr.find(a => a.id === mid).atar;

        if (arr.find(a => a.id === mid).aggr_lower > x)
            return binary_search(arr, x, start, mid - 1);
        else
            return binary_search(arr, x, mid + 1, end);
    };

    return (
        <div className="container">
            <div className="row mt5 d-flex justify-content-center">
                <InfoConsumer>
                    {
                        data => {
                            return (
                                <>
                                    <h2 className="mt-5 mb-5 text-center">ATAR Calculator</h2>
                                    <div className="container">
                                        <div className="row mt5">
                                            {
                                                (contributions.length < 6) ?
                                                    (
                                                        <>
                                                            <SelectCell
                                                                width={12}
                                                                id="subject_selection"
                                                                label="Subject Selector"
                                                                placeHolder={prompt}
                                                                options={selection.map(s => ({ label: s.title, value: s.code }))}
                                                                value={selectedValue}
                                                                onChange={e => { subject_selected(e.target.value); }}
                                                                resize={resize} />
                                                        </>
                                                    ) :
                                                    (
                                                        ""
                                                    )
                                            }
                                            
                                            {
                                                resize ?
                                                "" :
                                                <HeaderLine />
                                            }
                                            <hr/>
                                            <LabelCell
                                                width={12}
                                                label="English Subjects"
                                            />
                                            
                                            {
                                                <SubContributionSection
                                                    contributions={
                                                        contributions.find(c => subjects.find(s => s.code === c.code).english_subject) ?
                                                            [contributions.find(c => subjects.find(s => s.code === c.code).english_subject)] :
                                                            []
                                                    }
                                                    value_changed={value_changed}
                                                    remove_button_click={remove_button_click}
                                                    resize={resize}
                                                />
                                            }
                                            <hr/>
                                            <LabelCell
                                                width={12}
                                                label="Major Subjects"
                                            />
                                            
                                            {
                                                <SubContributionSection
                                                    contributions={
                                                        contributions.filter(c =>
                                                            c.major &&
                                                            c.code !== contributions.find(c => subjects.find(s => s.code === c.code).english_subject)?.code
                                                        )
                                                    }
                                                    value_changed={value_changed}
                                                    remove_button_click={remove_button_click}
                                                    resize={resize}
                                                />
                                            }
                                            <hr/>
                                            <LabelCell
                                                width={12}
                                                label="Additional Subjects"
                                            />
                                            
                                            {
                                                <SubContributionSection
                                                    contributions={contributions.filter(c => !c.major)}
                                                    value_changed={value_changed}
                                                    remove_button_click={remove_button_click}
                                                    resize={resize}
                                                />
                                            }
                                            <hr/>
                                            <LabelCell
                                                width={8}
                                                label="Aggregation: "
                                            />
                                            <LabelCell
                                                width={4}
                                                label={aggregation.toFixed(2)}
                                            />
                                            <LabelCell
                                                width={8}
                                                label="ATAR: "
                                            />
                                            <LabelCell
                                                width={4}
                                                label={`${(atar < 30) ? "< 30" : atar.toFixed(2)} %`}
                                            />

                                            <ButtonCell
                                                width={12}
                                                text="Clear"
                                                onClick={() => { clear() }}
                                                feature="btn-success"
                                            />
                                        </div>
                                    </div>
                                </>
                            );
                        }
                    }
                </InfoConsumer>
            </div>
        </div>
    );
}

export default AtarCalculator;

const interpolatingPolynomial = require('interpolating-polynomial');

const interpolatingLinear = (code) => {
    const scaling_array = scaling.find(s => s.code === code);
    return (x) => {
        return scaling_array.raw_20 / 20 * x;
    };
}

const get_scaling_matrix = (code) => {
    const scaling_array = scaling.find(s => s.code === code);
    return [...[20, 25, 30, 35, 40, 45, 50].map(a => [a, scaling_array[`raw_${a}`]]), ...[[55,56],[60,61]]];
};

const HeaderLine = () => {
    return (
        <>
            <LabelCell
                width={1}
                label="| Code |"
            />
            <LabelCell
                width={3}
                label="| Subject |"
            />
            <LabelCell
                width={2}
                label="| Raw Score |"
            />
            <LabelCell
                width={2}
                label="| Scaled Score |"
            />
            <LabelCell
                width={2}
                label="| Contribution |"
            />
            <LabelCell
                width={2}
                label=""
            />
        </>
    );
};

const SubContributionSection = ({ contributions, value_changed, remove_button_click, resize }) => {
    return (
        contributions.map(c =>
            <>
                <LabelCell
                    width={1}
                    label={`${resize ? "Code: " : ""}${c.code}`}
                />
                <LabelCell
                    width={3}
                    label={`${resize ? "Subject: " : ""}${c.title}`}
                />

                <TextCell
                    width={2}
                    label={resize ? "Raw Score" : ""}
                    value={c.raw}
                    onChange={e => { value_changed(c.code, e.target.value) }}
                />
                <TextCell
                    width={2}
                    label={resize ? "Scaled Score" : ""}
                    value={(c.raw > 20 ? c.pf(c.raw) : c.lf(c.raw)).toFixed(2)}
                    disabled={true}
                />
                <TextCell
                    width={2}
                    label={resize ? "Contribution" : ""}
                    value={((c.major ? 1 : 0.1) * (c.raw > 20 ? c.pf(c.raw) : c.lf(c.raw))).toFixed(2)}
                    disabled={true}
                />
                <ButtonCell
                    width={2}
                    label={resize ? "Contribution" : ""}
                    text="Remove"
                    onClick={() => { remove_button_click(c.code) }}
                    feature="btn-danger"
                />
            </>
        )
    );
};


const SelectCell = ({ width, id, label, placeHolder, value, options, onChange, resize }) => {
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            <div className="form-group">
                {resize ? "" : <StyledLabel htmlFor={id}>{label}</StyledLabel>}
                <select
                    id={id}
                    name={id}
                    className={`form-select ${value === "none" ? "select-placeholder" : ""}`}
                    value={value}
                    onChange={onChange}
                >
                    <option value="" hidden>{placeHolder}</option>
                    {options.map(o =>
                        <option value={o.value}>{o.label}</option>
                    )}
                </select>
            </div>
        </div>
    );
};

const LabelCell = ({ width, label }) => {
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            <StyledLabel>{label}</StyledLabel>
        </div>
    );
};

const TextCell = ({ width, label, value, onChange, disabled = false }) => {
    return (
        <div className={`form-group col-10 col-lg-${width} max-auto mb-3`}>
            {label ? <StyledLabel>{label}</StyledLabel> : ""}
            <input
                className="form-control"
                value={value}
                type="text"
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

const ButtonCell = ({ width, text, onClick, feature }) => {
    return (
        <>
            <div className={`col-10 col-lg-${width} max-auto mb-5 mr-0`}>
                <button type='button' className={`btn ${feature}`} onClick={onClick}>{text}</button>
            </div>
        </>
    );
};


const StyledForm = styled.form`
    input, select {
        width: 100%;
    }
    .select-placeholder {
        color: var(--mainLightGrey);
        option {
            color:black;
        }
    }
`;

const StyledLabel = styled.label`
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight:600;
`;