import React from 'react';
import styled from 'styled-components';
import { InfoConsumer } from '../Context';

const Footer = ({lan}) => {
    return (
        <InfoConsumer>
            {
                data => {
                    const content = data.footer_content.find(f => f.version === lan).content
                    return (
                        <FooterContainer className="main-footer">
                            <div className="footer-middle">
                                <div className="container">
                                    <div className="row">
                                        {/* Column 1 */}
                                        <div className="col-md-3 col-sm-6">
                                            <h4><i className="fas fa-landmark"></i>&nbsp;{content.campuses[0].name}</h4>
                                            <ul className="list-unstyled">
                                                <li><i className="fas fa-campground"></i>&nbsp;{content.campuses[0].address}</li>
                                                <li><i className="fas fa-phone"></i>&nbsp;&nbsp;{content.campuses[0].phone}</li>
                                            </ul>
                                        </div>
                                        {/* Column 2 */}
                                        <div className="col-md-3 col-sm-6">
                                        <h4><i className="fas fa-landmark"></i>&nbsp;{content.campuses[1].name}</h4>
                                            <ul className="list-unstyled">
                                                <li><i className="fas fa-campground"></i>&nbsp;{content.campuses[1].address}</li>
                                                <li><i className="fas fa-phone"></i>&nbsp;&nbsp;{content.campuses[1].phone}</li>
                                            </ul>
                                        </div>
                                        {/* Column 3 */}
                                        <div className="col-md-3 col-sm-6">
                                            <h4>{content.social.follow}</h4>
                                            <ul className="list-unstyled">
                                                <li><a className="text-decoration-none" href="#"><i className="fab fa-facebook-square"></i>&nbsp;{content.social.facebook}</a></li>
                                                <li><a className="text-decoration-none" href="#"><i className="fab fa-twitter-square"></i>&nbsp;{content.social.twitter}</a></li>
                                                <li><a className="text-decoration-none" href="#"><i className="fab fa-google-plus-square"></i>&nbsp;{content.social.googleplus}</a></li>
                                                <li><a className="text-decoration-none" href="#"><i className="fab fa-linkedin"></i>&nbsp;{content.social.linkedin}</a></li>
                                            </ul>
                                        </div>
                                        {/* Column 4 */}
                                        <div className="col-md-3 col-sm-6">
                                            <h4>{content.social.connect}</h4>
                                            <ul className="list-unstyled">
                                                <li><a className="text-decoration-none" href="#"><i className="fab fa-whatsapp"></i>&nbsp;{content.social.whatsapp}</a></li>
                                                <li><a className="text-decoration-none" href="#"><i className="fab fa-weixin"></i>&nbsp;{content.social.wechat}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Footer Bottom */}
                                    <div className="footer-bottom">
                                        <p className="text-xs-center">
                                            &copy;{new Date().getFullYear()} Kos Group Holdings Pty Ltd - All Rights Reserved
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </FooterContainer>
                    );
                }
            }
        </InfoConsumer>
    );
}

export default Footer;

const FooterContainer = styled.footer`
    .footer-middle {
        background: var(--mainGreen);
        padding-top: 3rem;
        color: var(--mainDarkGreen);
    }

    .footer-bottom {
        padding-top: 3rem;
        padding-bottom: 2rem;
    }

    ul li a {
        color: var(--mainGrey);
    }

    ul li a: hover {
        color: var(--mainLightGrey);
    }
`;