import React from 'react';
import styled from 'styled-components';

const SurveySection = ({
    parent,
    form_labels,
    lecture_time,
    hear_from,
    favorite_uni,
    favorite_major,
    lectureTime,
    hearFrom,
    specify,
    favoriteUni,
    favoriteMajor,
    setLectureTime,
    setHearFrom,
    setSpecify,
    setFavoriteUni,
    setFavoriteMajor,
    resize
}) => {

    const lecture_time_options = Object.keys(lecture_time ?? {}).map(key => {
        return {
            id: key,
            value: key,
            label: lecture_time[key]
        };
    });

    const hear_from_options = Object.keys(hear_from ?? {}).map(key => {
        return {
            id: key,
            value: key,
            label: hear_from[key]
        };
    });

    const favorite_uni_options = Object.keys(favorite_uni ?? {}).map(key => {
        return {
            id: key,
            value: key,
            label: favorite_uni[key]
        };
    });

    const favorite_major_options = Object.keys(favorite_major ?? {}).map(key => {
        return {
            id: key,
            value: key,
            label: favorite_major[key]
        };
    });

    return (
        <>
            {
                favorite_uni ?
                    <RadioGroup
                        width={4}
                        label={form_labels.favorite_uni}
                        options={favorite_uni_options}
                        compare={favoriteUni}
                        handler={setFavoriteUni}
                        required={true}
                        parent={parent}
                        prefix="fu"
                    /> :
                    ""
            }
            {
                favorite_major ?
                    <RadioGroup
                        width={4}
                        label={form_labels.favorite_major}
                        options={favorite_major_options}
                        compare={favoriteMajor}
                        handler={setFavoriteMajor}
                        required={true}
                        parent={parent}
                        prefix="fm"
                    /> :
                    ""
            }
            {
                lecture_time ?
                    <RadioGroup
                        width={12}
                        label={form_labels.lecture_time}
                        options={lecture_time_options}
                        compare={lectureTime}
                        handler={setLectureTime}
                        required={true}
                        parent={parent}
                        prefix="lt"
                    /> :
                    ""
            }
            {
                hear_from ?
                    <RadioGroup
                        width={4}
                        label={form_labels.hear_from}
                        options={hear_from_options}
                        compare={hearFrom}
                        handler={setHearFrom}
                        required={true}
                        parent={parent}
                        prefix="hf"
                    /> :
                    ""
            }
            <TextCell 
                width={12}
                id={"specify"}
                label={form_labels.specify}
                value={specify}
                onChange={e => setSpecify(e.target.value)}
                resize={resize}
            />
        </>
    );
};

export default SurveySection;

const RadioCell = ({ width, id, label, value, compare, onChange }) => {
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            <input
                type="radio"
                className="btn-check"
                id={id}
                name={id}
                value={value}
                checked={compare === value}
                onChange={onChange} />
            <label className="btn btn-outline-success" htmlFor={id}>{label}</label>
        </div>
    );
};

const RadioGroup = ({ width, label, options, compare, handler, required, parent, prefix }) => {
    return (
        <div className="col-10 col-lg-12 max-auto mb-3">
            <StyledLabel>{label} {required ? "*" : ""}</StyledLabel>
            <br />
            <div className="btn-group">
                <div className="container ">
                    <div className="row mt5">
                        {
                            options.map(option =>
                                <RadioCell
                                    width={width}
                                    id={`${parent}_${prefix}${option.id}`}
                                    label={option.label}
                                    value={option.value}
                                    compare={compare}
                                    onChange={e => handler(e.target.value)}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const TextCell = ({ width, id, label, value, onChange, required, resize }) => {
    return (
        <div className={`form-group col-10 col-lg-${width} max-auto mb-3`}>
            {resize?"":<StyledLabel htmlFor={id}>{label} {required ? "*" : ""}</StyledLabel>}
            <input
                id={id}
                name={id}
                className="form-control"
                value={value}
                placeholder={label}
                type="text"
                onChange={onChange} />
        </div>
    );
};

const StyledLabel = styled.label`
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight:600;
`;