import React, { version } from 'react';
import GetScores from '../Functions/GetScores';
import { useAsync } from 'react-async-hook';

import {
    header_content,
    footer_content,
    home_content,
    about_content,
    lectures_content,
    news_content,
    courses_content,
    join_content,
    contact_content,
    score_list,
    photo_gallaries
} from '../data';
import GetNewsList from '../Functions/GetNewsList';
import GetVideos from '../Functions/GetVideos';

const InfoContext = React.createContext();

const InfoProvider = props => {

    const fetchScores = async () => {
        return (await GetScores()).map(score => {
            return {
                name: score.name,
                atar: score.atar,
                school: score.school
            };
        });
    };

    const asyncScores = useAsync(fetchScores);

    const fetchNewsList = async (language) => {
        return (await GetNewsList(language)).map(news => {
            return {
                id: news._id,
                title: news.title,
                img: "/news.png",
                paragraphs: news.paragraphs.map(p => {
                    return {
                        id: p._id,
                        text: p.text,
                        img: {
                            path: "/news.png",
                            position: p.image_position === "top"? "before" : p.image_position === "bottom"? "after": p.image_position
                        }
                    };
                })
            };
        });
    };

    const asyncEnNewsList = useAsync(fetchNewsList, ["English"]);
    const syncChNewsList = useAsync(fetchNewsList, ["Chinese"]);

    const fectchVideos = async (language) => {
        return (await GetVideos(language)).map(video => {
            return {
                title: video.title,
                embedId: video.link
            };
        });
    };

    const asyncEnVideos = useAsync(fectchVideos, ["English"]);
    const syncChVideos = useAsync(fectchVideos, ["Chinese"]);

    const news_content_dynamic = news_content.map(c => {
        return {
            version: c.version,
            content: {
                news_title: c.content.news_title,
                video_title: c.content.video_title,
                expand_btn_text: c.content.expand_btn_text,
                collapse_btn_text: c.content.collapse_btn_text,
                news_list: c.version === "en" ? (asyncEnNewsList.result || []) : c.version === "ch" ? (syncChNewsList.result || []) : [],
                videos: c.version === "en" ? (asyncEnVideos.result || []) : c.version === "ch" ? (syncChVideos.result || []) : []
            }
        };
    });

    console.log(JSON.stringify(news_content_dynamic, null, 4));

    return (
        <InfoContext.Provider value={{
            header_content: header_content,
            footer_content: footer_content,
            home_content: home_content,
            about_content: about_content,
            lectures_content: lectures_content,
            news_content: news_content_dynamic,
            courses_content: courses_content,
            join_content: join_content,
            contact_content: contact_content,
            score_list: asyncScores.result || [],
            photo_gallaries: photo_gallaries
        }}>
            {props.children}
        </InfoContext.Provider>
    );
};

const InfoConsumer = InfoContext.Consumer;

export { InfoProvider, InfoConsumer };