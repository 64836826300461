import { Link } from 'react-router-dom';
import styled from 'styled-components'; 

const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }

    color: var(--mainGrey);

    &:hover {
        color: var(--mainLightGrey);
    }
`;

export default StyledLink;