import React from 'react';

const AdmissionSection = ({ title, img }) => {
    return (
        <div className="col-10 col-lg-12 max-auto mb-5 mr-0">
            <div className="card" >
                <div className="row card-body">
                    <h3 className="card-title mt-2 mb-2">
                        {title}
                    </h3>
                    <img src={img} alt={title} className="mt-2 h-100"  />
                </div>
            </div>
        </div>
    );
}

export default AdmissionSection;