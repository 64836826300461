import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Components/Layouts/Navbar';
import Footer from './Components/Layouts/Footer';
import Home from './Components/Pages/Home';
import AboutUs from './Components/Pages/AboutUs';
import Lectures from './Components/Pages/Lectures';
import News from './Components/Pages/News';
import Course from './Components/Pages/Course';
import JoinUs from './Components/Pages/JoinUs';
import Contact from './Components/Pages/Contact';
import AtarCalculator from './Components/Pages/AtarCalculator';
import NotFoundPage from './Components/Pages/NotFoundPage';
import useModal from './Hooks/useModal';
import AtarCalc from './Components/Pages/AtarCalc';
import useLanguage from './Hooks/useLanguage';
import FontWrapper from './Components/FontWrapper';
import NewsDetail from './Components/Pages/NewsDetail';
import styled from 'styled-components';
import InfoBar from './Components/Layouts/InfoBar';


const App = () => {

    //console.log(process.env);
    

    const { language, toggleLanguage } = useLanguage();
    const { modalOpen: AtarOpen, setModalOpen: setAtarOpen, toggleModalStatus: toggleAtar } = useModal();
    const { modalOpen: ContactOpen, setModalOpen: setContactOpen, toggleModalStatus: toggleContact } = useModal();
    return (
        <FontWrapper version={language}>
            {/* Navbar */}
            <Navbar handleCalc={toggleAtar} handleContact={toggleContact} lan={language} handleLan={toggleLanguage}></Navbar>

            {/* ATAT Calculator Modal */}
            <AtarCalc
                isActive={AtarOpen}
                handleClose={() => setAtarOpen(false)}
            />

            {/* Contact Form Modal, to be implemented */}
            {
                <Contact
                    lan={language}
                    isActive={ContactOpen}
                    handleClose={() => setContactOpen(false)}
                />
            }

            {/* Routing */}
            <ContentWrapper>
                <Routes>
                    <Route path="/" element={<Home lan={language} />} />
                    <Route path="/aboutus" element={<AboutUs lan={language} />} />
                    <Route path='/lectures' element={<Lectures lan={language} />} />
                    <Route path='/news' element={<News lan={language} />} />
                    {/* Courses */}
                    <Route path='/course/:code' element={<Course lan={language} />} />
                    <Route path='/joinus' element={<JoinUs lan={language} />} />
                    {/* <Route path='/contact' element={<Contact lan = {language} />} /> */}
                    {/* News Details */}
                    <Route path='/news-detail/:id' element={<NewsDetail lan={language} />} />
                    <Route path='/contact' element={<Contact lan={language} isModal={false} />} />
                    <Route path='/atar-calc' element={<AtarCalculator lan={language} />} />
                    {/* Default */}
                    <Route path='*' element={<NotFoundPage />} />
                </Routes>
            </ContentWrapper>
            {/* Footer */}
            <Footer lan={language}></Footer>
            <InfoBar lan={language} />

        </FontWrapper>

    );

};

export default App;

const ContentWrapper = styled.div`
    margin-top: 100px;
    background-color: var(--mainLightGreen);
    h2, h3, h5 {
        color: var(--mainDarkGreen);
    }
`;
