import React from 'react';
import styled from 'styled-components';
import useBioInfo from '../../Hooks/useBioInfo';
import useSurvey from '../../Hooks/useSurvey';
import BioInfoSection from './BioInfoSection';
import SurveySection from './SurveySection';
import SubmitForm from '../../Functions/SubmitForm';
import { ValidateLectureForm } from '../../Functions/Validate';

const other = "other"

const AeasLectureSection = ({
    form_labels,
    genders,
    grades,
    campuses,
    hear_from,
    error_book,
    resize }) => {
    const {
        students,
        validation,
        add,
        remove,
        update,
        reset,
        setValidation } = useBioInfo({
            error_book: error_book
        });

    const {
        hearFrom,
        specify,
        setHearFrom,
        setSpecify
    } = useSurvey({
        defaultHearFrom: other
    });

    const handle_submit = async (e) => {
        e.preventDefault();

        const result = await SubmitForm(
            {
                students: students,
                hear_from: hearFrom,
                specify: specify
            },
            "/lecture/app/aeas");

        ValidateLectureForm(students, result, setValidation, reset);

        // if (validation.validated)
        //     reset();
    };

    return (
        <>
            <StyledForm onSubmit={e => { handle_submit(e) }}>
                <div className="container ">
                    <div className="row mt5">
                        <BioInfoSection
                            form_labels={form_labels}
                            genders={genders}
                            grades={grades}
                            campuses={campuses}
                            error_book={error_book}
                            students={students}
                            add={add}
                            remove={remove}
                            update={update}
                            apply_private_school={true}
                            resize={resize}
                            validation={validation}
                        />
                        <SurveySection
                            parent="aeas"
                            form_labels={form_labels}
                            hear_from={hear_from}
                            hearFrom={hearFrom}
                            setHearFrom={setHearFrom}
                            specify={specify}
                            setSpecify={setSpecify}
                            resize={resize}
                        />
                        <SubmitCell width={2} text={form_labels.submit} />
                    </div>
                </div>
            </StyledForm>

        </>
    );
};

export default AeasLectureSection;

const StyledForm = styled.form`
    input, select {
        width: 100%;
    }
`;

const SubmitCell = ({ width, text }) => {
    return (
        <>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-5 mr-0`}></div>
            <div className={`col-10 col-lg-${width} max-auto mb-5 mr-0`}>
                <input type="submit" className="btn btn-success" value={text} />
            </div>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-5 mr-0`}></div>
        </>
    );
};