import React from 'react';
import { InfoConsumer } from '../Context';
import JobApplicationSection from '../Sections/JobApplicationSection';
import InfoSection from '../Sections/InfoSection';
import AchSection from '../Sections/AchSection';
import styled from 'styled-components';

const JoinUs = ({ lan }) => {
    return (
        <div className="container">
            <div className="row mt5 d-flex justify-content-center">
                <InfoConsumer>
                    {
                        data => {
                            const content = data.join_content.find(j => j.version === lan).content;
                            const form_labels = content.form_labels;
                            const genders = content.genders;
                            const hear_from = content.hear_from;
                            const error_book = content.error_book;
                            return (
                                <>
                                    <h2 className="mt-5 mb-5 text-center">{content.title}</h2>
                                    {
                                        content.positions.map(p =>
                                            <InfoSection
                                                img={p.img}
                                                title={p.name}
                                                text={p.description}
                                                imgPosition={"before"}
                                                size={6}
                                            />
                                        )
                                    }
                                    <h2 className="mt-5 mb-5 text-center">{content.welfare_title}</h2>
                                    {
                                        content.welfare.map(w =>
                                            <AchSection logo={w.icon} text={w.text} />
                                        )
                                    }
                                    <h2 className="mt-5 mb-5 text-center">{content.job_app_title}</h2>
                                    <div className="card">
                                        <StyledJobAppWrapp>
                                            <JobApplicationSection
                                                form_labels={form_labels}
                                                genders={genders}
                                                hear_from={hear_from}
                                                error_book={error_book}
                                            />
                                        </StyledJobAppWrapp>
                                    </div>
                                    <p>
                                        <br />
                                    </p>
                                </>
                            );
                        }
                    }
                </InfoConsumer>
            </div>
        </div>
    );
}

export default JoinUs;

const StyledJobAppWrapp = styled.div`
.btn {
    width:200px;
}

.btn-group .container {
    margin-left: -20px;
}

@media only screen and (max-device-width:480px) {
    .btn {
        width:180px;
        font-size:12px;
    }

    .btn-group .btn {
        width:100px;
        font-size:8px;
    }

    .second {
        position:relative;
        left:50px;
    }

    .form-select {
        font-size:12px;
        width:180px;
    }

    .form-control {
        font-size:12px;
        width:180px;
    }
}
`;