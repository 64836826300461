import React from 'react';
import { useLocation } from 'react-router-dom';
import StyledLink from '../StyledLink';

const NewsSection = ({ id, img, title }) => {
    const url = `/news-detail/${id}`;
    const location = useLocation();
    return (
        <div className="col-10 col-lg-4 max-auto mb-5">
            <div className="card" style={{ width: '16rem' }}>
                <div className="card-body">
                <StyledLink to={url} state={{from:location.pathname}}>
                    <img  src={img} alt={title}  className="card-title h-100 w-100" />
                </StyledLink>
                <StyledLink to={url} state={{from:location.pathname}}>
                    <h5 className="card-title">
                        {title}
                    </h5>
                </StyledLink>
            </div>
        </div>
        </div >
    );
}

export default NewsSection;

