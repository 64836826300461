import React from 'react';
import { useParams } from 'react-router-dom';
import { InfoConsumer } from '../Context';
import CoursePrimarySection from '../Sections/CoursePrimarySection';
import CourseSecondarySection from '../Sections/CourseSecondarySection';

const Course = ({ lan }) => {
    const { code } = useParams();
    return (
            <div className="container">
                <div className="row row-eq-height mt5 d-flex justify-content-center">
                    <InfoConsumer>
                        {
                            data => {
                                const content = data.courses_content.find(c => c.version === lan).courses.find(c => c.code === code);
                                return (
                                    <>
                                        <h2 className="mt-5 mb-5 text-center">{content.title}</h2>
                                        {content.primary ?
                                            <CoursePrimarySection title={content.primary.title} text={content.primary.text} labels={content.primary.labels} /> :
                                            ""
                                        }
                                        {
                                            content.secondaries ?
                                                content.secondaries.map(s =>
                                                    <CourseSecondarySection title={s.title} text={s.text} features={s.features} />
                                                ) : ""
                                        }
                                    </>
                                );
                            }
                        }
                    </InfoConsumer>
                </div>
            </div>
    );
}

export default Course;
