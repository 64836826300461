import GetToken from "./GetToken";

const GetScores = async () => {

    const result = await (await GetToken()).json();

    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/scores`,
        {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${result.access_token}`
            }
        }
    );

    return res.json();
}

export default GetScores;