import { update } from 'lodash';
import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import SubmitForm from '../../Functions/SubmitForm';
import ToBase64 from '../../Functions/ToBase64';
import { ValidateJobForm } from '../../Functions/Validate';
import useApplication from '../../Hooks/useApplication';

const JobApplicationSection = ({
    form_labels,
    genders,
    hear_from,
    error_book
}) => {
    const {
        application,
        validation,
        update,
        add_education,
        remove_education,
        update_education,
        add_experience,
        remove_experience,
        update_experience,
        reset,
        setValidation
    } = useApplication({ genders, error_book });

    const handle_submit = async (e) => {
        e.preventDefault();
        const {
            applicant_name,
            applicant_chinese_name,
            mobile,
            email,
            social_media,
            gender,
            dob,
            position,
            subject,
            specified_subject,
            education,
            experience,
            uploaded_file,
            hear_from
        } = application;

        console.log(JSON.stringify({
            name: applicant_name,
            chinese_name: applicant_chinese_name,
            mobile: mobile,
            email: email,
            wechat_qq: social_media,
            gender: gender,
            dob: dob,
            position: position,
            subject: subject,
            subject_specified: specified_subject,
            hear_from: hear_from,
            edu_background: education,
            work_experience: experience,
            resume_base64: uploaded_file.base64,
            resume_name: uploaded_file.file_name
        }, null, 4));



        const result = await SubmitForm({
            name: applicant_name,
            chinese_name: applicant_chinese_name,
            mobile: mobile,
            email: email,
            wechat_qq: social_media,
            gender: gender,
            dob: dob,
            position: position,
            subject: subject,
            subject_specified: specified_subject,
            hear_from: hear_from,
            edu_background: education,
            work_experience: experience,
            resume_base64: uploaded_file.base64,
            resume_name: uploaded_file.file_name
        }, "/job/app");

        ValidateJobForm(result, setValidation, reset);

        // if (validation.validated)
        //     reset();

        //alert("done");
    };

    const handle_file_upload = async (e) => {
        const file_name = e.target.value.split("C:\\fakepath\\")[1];
        const pieces = file_name.split(".");
        if (!["pdf", "docx", "doc"].some(arr => arr === pieces[pieces.length - 1].toLowerCase())) {
            alert("Please only upload Word or PDF document.")
            e.target.value = null;
        }
        else
            update("uploaded_file", {
                file_name: file_name,
                base64: (await ToBase64(e.target.files[0]))?.split("base64,")[1]
            });
    }

    const gender_options = Object.keys(genders).filter(key => key !== "none").map(key => {
        return {
            value: key,
            label: genders[key]
        };
    });

    const hear_from_options = Object.keys(hear_from).map(key => {
        return {
            id: key,
            value: key,
            label: hear_from[key]
        };
    });


    //Handle Resize
    const [resize, setResize] = useState(false);

    const handleResize = () => {
        if (window.innerWidth > 760)
            setResize(false);
        else
            setResize(true);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
    }, []);

    return (
        <StyledForm onSubmit={e => { handle_submit(e) }} >
            <div className="container">
                <div className="row mt5">
                    <div className="col-10 col-lg-12 max-auto mb-3 mr-0">
                        <div className="container">
                            <div className="row mt5">
                                <div className={`col-10 col-lg-12 max-auto mb-3`}>
                                    <br />
                                </div>
                                <TextboxCell
                                    width={6}
                                    label={form_labels.applicant_name}
                                    warning={validation?.errors.find(err => err?.field_name == "name")?.reason??""}
                                    id="applicant_name"
                                    value={application.applicant_name}
                                    onChange={e => update("applicant_name", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextboxCell
                                    width={6}
                                    label={`${form_labels.applicant_ch_name}`}
                                    id="applicant_chinese_name"
                                    value={application.applicant_chinese_name}
                                    onChange={e => update("applicant_chinese_name", e.target.value)}
                                    resize={resize}
                                />
                                <TextboxCell
                                    width={4}
                                    label={form_labels.mobile}
                                    warning={validation?.errors.find(err => err?.field_name == "mobile")?.reason??""}
                                    value={application.mobile}
                                    id="mobile"
                                    onChange={e => update("mobile", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextboxCell
                                    width={4}
                                    label={form_labels.email}
                                    warning={validation?.errors.find(err => err?.field_name == "email")?.reason??""}
                                    id="email"
                                    value={application.email}
                                    onChange={e => update("email", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextboxCell
                                    width={4}
                                    label={form_labels.social_media}
                                    id="social_media"
                                    value={application.social_media}
                                    onChange={e => update("social_media", e.target.value)}
                                    required={false}
                                    resize={resize}
                                />
                                <SelectCell
                                    width={3}
                                    label={form_labels.gender}
                                    warning={validation?.errors.find(err => err?.field_name == "gender")?.reason??""}
                                    id="gender"
                                    value={application.gender}
                                    onChange={e => update("gender", e.target.value)}
                                    options={gender_options}
                                    required={true}
                                    resize={resize}
                                />
                                <TextboxCell
                                    width={5}
                                    label={form_labels.dob}
                                    warning={validation?.errors.find(err => err?.field_name == "dob")?.reason??""}
                                    id="dob"
                                    value={application.dob}
                                    onChange={e => update("dob", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextboxCell
                                    width={4}
                                    label={form_labels.position}
                                    warning={validation?.errors.find(err => err?.field_name == "position")?.reason??""}
                                    id="position"
                                    value={application.position}
                                    onChange={e => update("position", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextboxCell
                                    width={6}
                                    label={form_labels.subject}
                                    warning={validation?.errors.find(err => err?.field_name == "subject")?.reason??""}
                                    id="subject"
                                    value={application.subject}
                                    onChange={e => update("subject", e.target.value)}
                                    required={true}
                                    resize={resize}
                                />
                                <TextboxCell
                                    width={6}
                                    label={form_labels.specified_subject}
                                    id="specified_subject"
                                    value={application.specified_subject}
                                    onChange={e => update("specified_subject", e.target.value)}
                                    required={false}
                                    resize={resize}
                                />
                                <LabelCell width={12} label={form_labels.education_background} />
                                {
                                    application.education.map(edu =>
                                        <>
                                            <TextboxCell
                                                width={3}
                                                label={form_labels.period}
                                                id={`education_period${edu.id}`}
                                                value={edu.period}
                                                onChange={e => update_education(edu.id, "period", e.target.value)}
                                                resize={resize}
                                            />
                                            <TextboxCell
                                                width={3}
                                                label={form_labels.collage}
                                                id={`education_collage${edu.id}`}
                                                value={edu.collage}
                                                onChange={e => update_education(edu.id, "collage", e.target.value)}
                                                resize={resize}
                                            />
                                            <TextboxCell
                                                width={3}
                                                label={form_labels.qualification}
                                                id={`education_qualification${edu.id}`}
                                                value={edu.qualification}
                                                onChange={e => update_education(edu.id, "qualification", e.target.value)}
                                                resize={resize}
                                            />
                                            <TextboxCell
                                                width={3}
                                                label={form_labels.major}
                                                id={`education_major${edu.id}`}
                                                value={edu.major}
                                                onChange={e => update_education(edu.id, "major", e.target.value)}
                                                resize={resize}
                                            />
                                            {
                                                (application.education.length > 1) ?
                                                    <ButtonCell
                                                        width={2}
                                                        text={form_labels.remove}
                                                        onClick={() => remove_education(edu.id)}
                                                        feature="btn-danger"
                                                    /> :
                                                    ""
                                            }
                                        </>
                                    )
                                }
                                <ButtonCell
                                    width={4}
                                    text={form_labels.add_edu}
                                    onClick={() => add_education()}
                                    feature="btn-success"
                                />
                                <LabelCell width={12} label={form_labels.working_experience} />
                                {
                                    application.experience.map(exp =>
                                        <>
                                            <TextboxCell
                                                width={3}
                                                label={form_labels.period}
                                                id={`experience_period${exp.id}`}
                                                value={exp.period}
                                                onChange={e => update_experience(exp.id, "period", e.target.value)}
                                                resize={resize}
                                            />
                                            <TextboxCell
                                                width={5}
                                                label={form_labels.employer}
                                                id={`experience_employer${exp.id}`}
                                                value={exp.employer}
                                                onChange={e => update_experience(exp.id, "employer", e.target.value)}
                                                resize={resize}
                                            />
                                            <TextboxCell
                                                width={4}
                                                label={form_labels.pre_position}
                                                id={`experience_position${exp.id}`}
                                                value={exp.position}
                                                onChange={e => update_experience(exp.id, "position", e.target.value)}
                                                resize={resize}
                                            />
                                            {
                                                (application.experience.length > 1) ?
                                                    <ButtonCell
                                                        width={2}
                                                        text={form_labels.remove}
                                                        onClick={() => remove_experience(exp.id)}
                                                        feature="btn-danger"
                                                    /> :
                                                    ""
                                            }
                                        </>
                                    )
                                }
                                <ButtonCell
                                    width={4}
                                    text={form_labels.add_experience}
                                    onClick={() => add_experience()}
                                    feature="btn-success"
                                />
                                <RadioGroup
                                    width={resize ? 6 : 4}
                                    label={form_labels.hear_from}
                                    options={hear_from_options}
                                    compare={application.hear_from}
                                    onChange={e => update("hear_from", e.target.value)}
                                />
                                <div className="form-group files">
                                    <StyledLabel>Upload your resume</StyledLabel>
                                    <input type="file"
                                        className="form-control"
                                        multiple=""
                                        // value={application.uploaded_file.text}
                                        onChange={handle_file_upload}
                                    />
                                </div>
                                <SubmitCell width={2} text={form_labels.submit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledForm>
    );
};

export default JobApplicationSection;

const LabelCell = ({ width, label }) => {
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            <StyledLabel>{label}</StyledLabel>
        </div>
    );
};

const SubmitCell = ({ width, text }) => {
    return (
        <StyledSubmit>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-3`}></div>
            <div className={`col-10 col-lg-${width} max-auto mb-3`}>
                <input type="submit" className="btn btn-success" value={text} />
            </div>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-3`}></div>
        </StyledSubmit>
    );
}

const ButtonCell = ({ width, text, onClick, feature }) => {
    return (
        <>
            <div className={`col-10 col-lg-${width} max-auto mb-3`}>
                <button type='button' className={`btn ${feature}`} onClick={onClick}>{text}</button>
            </div>
            <div className={`col-10 col-lg-${12 - width} max-auto mb-3`}></div>
        </>
    );
};

const RadioCell = ({ width, label, id, value, compare, onChange, index }) => {
    return (
        <div className={`col-${width} max-auto mb-3 ${index % 2 === 0 ? "second" : "first"}`}>
            <input
                type="radio"
                className={`btn-check`}
                id={id}
                name={id}
                value={value}
                checked={value === compare}
                onChange={onChange}
            />
            <label className={`btn btn-outline-success`} htmlFor={id}>{label}</label>
        </div>
    );
};

const RadioGroup = ({ width, label, options, compare, onChange, required }) => {
    let index = 1;
    return (
        <>
            <StyledLabel>{label} {required ? "*" : ""}</StyledLabel>
            <div className="btn-group">
                <div className="container">
                    <div className="row mt5">
                        {
                            options.map(option =>
                                <RadioCell
                                    width={width}
                                    label={option.label}
                                    id={option.id}
                                    value={option.value}
                                    compare={compare}
                                    onChange={onChange}
                                    index={index++}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

const TextboxCell = ({ width, label, id, value, onChange, required, resize, warning = "" }) => {
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            {resize ? "" : <StyledLabel>{label} {required ? "*" : ""} <span style={{ color: "red" }}>{warning}</span></StyledLabel>}
            <input
                id={id}
                name={id}
                className="form-control"
                value={value}
                placeholder={label}
                type="text"
                onChange={onChange} />
        </div>
    );
};

const SelectCell = ({ width, label, id, value, onChange, options, required, resize, warning = "" }) => {
    console.log(`value: ${value}`);
    return (
        <div className={`col-10 col-lg-${width} max-auto mb-3`}>
            {resize ? "" : <StyledLabel>{label} {required ? "*" : ""} <span style={{ color: "red" }}>{warning}</span></StyledLabel>}
            <select
                id={id}
                name={id}
                className={`form-select ${value === "none" ? "select-placeholder" : ""}`}
                value={value}
                onChange={onChange}
            >
                <option value="" hidden>{label}</option>
                {
                    options.map(o =>
                        <option value={o.value}>{o.label}</option>
                    )
                }
            </select>
        </div>
    );
};

const StyledForm = styled.form`
    input, select {
        width: 100%;
    }
    .select-placeholder {
        color: var(--mainLightGrey);
        option {
            color:black;
        }
    }
`;

const StyledLabel = styled.label`
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight:600;
`;

const StyledSubmit = styled.div`
    .btn-primary {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;