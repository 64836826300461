import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import StyledLink from '../StyledLink';
import { InfoConsumer } from '../Context';
import InfoSection from '../Sections/InfoSection';

const NewsDetail = ({lan}) => {
    const {id} = useParams();
    const location = useLocation();
    return (
        <InfoConsumer>
            {
                data => {
                    const content = data.news_content.find(n => n.version === lan).content;
                    const news = content.news_list.find(n => n.id == id);
                    return (
                        <>
                            <h2>{news.title}</h2> 
                            {
                                news.paragraphs.map(p => 
                                    <InfoSection img={p.img?p.img.path:"none"} imgPosition={p.img?p.img.position:"none"} text={p.text} />    
                                )
                            }
                            <StyledLink to={location.state.from}>Back</StyledLink>
                        </> 
                    ); 
                }
            }
        </InfoConsumer>  
    );
}

export default NewsDetail;