import {useState} from 'react';

const useNews = ({leastNum = 3, news_list}) => {
    const [numShown, setNumShown] = useState(leastNum);
    const [expandVisable, setExpandVisable] = useState(true);
    const [collapseVisable, setCollapseVisable] = useState(false);
    const expand = () => {
        var newNumberShown = numShown + leastNum > news_list.length? news_list.length : numShown + leastNum;
        setNumShown(newNumberShown);
        if(newNumberShown === news_list.length){
            setExpandVisable(false);
            setCollapseVisable(true);
        }
            
    };
    const collapse = () => {
        var newNumberShown = news_list.length > leastNum? leastNum : news_list.length;
        setNumShown(newNumberShown);
        if(newNumberShown <= leastNum){
            setCollapseVisable(false);
            setExpandVisable(true);
        } 
    }

    return {numShown, expandVisable, collapseVisable, expand, collapse};
};

export default useNews;