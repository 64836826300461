import {useState} from 'react';

const useScoreList = ({leastNum = 6, totalNum, openText = "Show All", closeText = "Collapse"}) =>{
    const [scores, setScores] = useState(null);
    const [numShow, setNumShow] = useState(leastNum);
    const [text, setText] = useState(openText);
    const toggleScoreList = () => {
        if(numShow < totalNum){
            setNumShow(totalNum);
            setText(closeText);
        }
        else{
            setNumShow(leastNum);
            setText(openText);
        }
    };

    return{scores, numShow, text, setScores, toggleScoreList};
}

export default useScoreList;

