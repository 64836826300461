import React from 'react';
import styled from 'styled-components';
import useBioInfo from '../../Hooks/useBioInfo';
import useSurvey from '../../Hooks/useSurvey';
import BioInfoSection from './BioInfoSection';
import SurveySection from './SurveySection';
import { useEffect } from 'react';
import SubmitForm from '../../Functions/SubmitForm';
import { ValidateLectureForm } from '../../Functions/Validate';

const date_time1 = "date_time1";
const other = "other"

const CeeLectureSection = ({
    form_labels,
    genders,
    grades,
    campuses,
    lecture_time,
    hear_from,
    favorite_uni,
    favorite_major,
    error_book,
    resize }) => {
    const {
        students,
        validation,
        add,
        remove,
        update,
        reset,
        setValidation } = useBioInfo({
            error_book: error_book
        });

    const {
        lectureTime,
        hearFrom,
        specify,
        favoriteUni,
        favoriteMajor,
        setLectureTime,
        setHearFrom,
        setSpecify,
        setFavoriteUni,
        setFavoriteMajor
    } = useSurvey({
        defaultLectureTime: lecture_time[0],
        defaultHearFrom: other,
        defaultFavoriteUni: other,
        defaultFavoriteMajor: other
    });

    const lecture_time_object = (Array.isArray(lecture_time)) ? lecture_time.reduce((accumulator, value) => {
        return { ...accumulator, [value]: value };
    }, {}) : lecture_time;

    useEffect(() => {
        setLectureTime(lecture_time[0]);
    }, [lecture_time[0]]);

    const handle_submit = async (e) => {
        e.preventDefault();
        
        const result = await SubmitForm(
            {
                students: students,
                lecture_time: lectureTime,
                hear_from: hearFrom,
                specify: specify,
                favorite_uni: favoriteUni,
                favorite_major: favoriteMajor
            },
            "/lecture/app/cee");

            ValidateLectureForm(students, result, setValidation, reset);

            // if (validation.validated)
            //     reset();
    };

    return (
        <>
            <StyledForm onSubmit={e => { handle_submit(e) }}>
                <div className="container ">
                    <div className="row mt5">
                        <BioInfoSection
                            form_labels={form_labels}
                            genders={genders}
                            grades={grades}
                            campuses={campuses}
                            error_book={error_book}
                            students={students}
                            add={add}
                            remove={remove}
                            update={update}
                            resize={resize}
                            validation={validation}
                        />
                        <SurveySection
                            parent="cee"
                            form_labels={form_labels}
                            lecture_time={lecture_time_object}
                            hear_from={hear_from}
                            favorite_uni={favorite_uni}
                            favorite_major={favorite_major}
                            lectureTime={lectureTime}
                            setLectureTime={setLectureTime}
                            hearFrom={hearFrom}
                            setHearFrom={setHearFrom}
                            specify={specify}
                            setSpecify={setSpecify}
                            favoriteUni={favoriteUni}
                            setFavoriteUni={setFavoriteUni}
                            favoriteMajor={favoriteMajor}
                            setFavoriteMajor={setFavoriteMajor}
                            resize={resize}
                        />
                        <SubmitCell width={2} text={form_labels.submit} />
                    </div>
                </div>
            </StyledForm>

        </>
    );
};

export default CeeLectureSection;

const StyledForm = styled.form`
    input, select {
        width: 100%;
    }
`;

const SubmitCell = ({ width, text }) => {
    return (
        <>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-5 mr-0`}></div>
            <div className={`col-10 col-lg-${width} max-auto mb-5 mr-0`}>
                <input type="submit" className="btn btn-success" value={text} />
            </div>
            <div className={`col-10 col-lg-${(12 - width) / 2} max-auto mb-5 mr-0`}></div>
        </>
    );
};